import { RootState } from "..";
import { UsersState } from "./users.reducer";
import { User } from "../../http/requests/users";

export const selectUsersState = (state: RootState): UsersState => {
    return state.users;
}

export const selectUsersLoading = (state: RootState): boolean => {
    return selectUsersState(state).loading;
}

export const selectUsers = (state: RootState): User[] => {
    return selectUsersState(state).data;
}

export const selectUser = (userId: number) => (state: RootState): User => {
    const users = selectUsers(state);
    return users.filter(user => user.id === userId)[0];
}