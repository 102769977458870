import { Epic, ofType } from 'redux-observable';
import { RootActions } from '..';
import { UsersActions, loadUsersSuccess } from './users.actions';
import { LOAD_USERS } from './users.action-types';
import { getUsers } from '../../http/requests/users';
import { switchMap, map } from 'rxjs/operators';

export const loadUsersEpic: Epic<RootActions, UsersActions> = action$ => action$.pipe(
    ofType(LOAD_USERS),
    switchMap(() => {
        return getUsers().pipe(
            map(response => loadUsersSuccess(response.data))
        )
    })
)

export const usersEpics = [loadUsersEpic];
