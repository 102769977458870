import { ChannelsState, channelsReducer } from "./channels/channels.reducer";
import { combineReducers, createStore, CombinedState, applyMiddleware, compose } from "redux";
import { ChannelsActions } from "./channels/channels.actions";
import { ProductsActions } from "./products/products.actions";
import { ProductsState, productsReducer } from "./products/products.reducer";
import { ProfileActions } from "./profile/profile.actions";
import { ProfileState, profileReducer } from "./profile/profile.reducer";
import { UsersActions } from "./users/users.actions";
import { UsersState, usersReducer } from "./users/users.reducer";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { channelEpics } from "./channels/channels.epics";
import { productsEpics } from "./products/products.epics";
import { usersEpics } from "./users/users.epics";
import { profileEpics } from "./profile/profile.epics";
import { channelProductsEpics } from "./channelProducts/channelProducts.epics";
import { ChannelProductsActions } from "./channelProducts/channelProducts.actions";
import { channelProductsReducer, ChannelProductsState } from "./channelProducts/channelProducts.reducer";
import { LoginActions } from "./login/login.actions";
import { LoginState, loginReducer } from "./login/login.reducer";
import { loginEpics } from "./login/login.epics";
import { RegisterActions } from "./register/register.actions";
import { registerReducer, RegisterState } from "./register/register.reducer";
import { registerEpics } from "./register/register.epics";
import subscribeActionMiddleware from 'redux-subscribe-action';
import { FollowingsActions } from "./followers/followings.actions";
import { FollowingsState, followingsReducer } from "./followers/followings.reducer";
import { followingsEpics } from "./followers/followings.epics";

export type RootActions = 
    ChannelsActions |
    ProductsActions |
    ProfileActions |
    UsersActions |
    ChannelProductsActions |
    LoginActions |
    RegisterActions |
    FollowingsActions;

export interface RootState {
    channels: ChannelsState;
    products: ProductsState;
    profile: ProfileState;
    users: UsersState;
    channelProducts: ChannelProductsState;
    account: LoginState;
    register: RegisterState;
    followings: FollowingsState;
}

const rootReducer = combineReducers({
    channels: channelsReducer,
    products: productsReducer,
    profile: profileReducer,
    users: usersReducer,
    channelProducts: channelProductsReducer,
    account: loginReducer,
    register: registerReducer,
    followings: followingsReducer,
});

const rootEpic = combineEpics(
    ...channelEpics,
    ...productsEpics,
    ...usersEpics,
    ...profileEpics,
    ...channelProductsEpics,
    ...loginEpics,
    ...registerEpics,
    ...followingsEpics,
)

const epicMiddleware = createEpicMiddleware();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
    const store = createStore<CombinedState<RootState>, RootActions, null, null>(
        rootReducer,
        composeEnhancers(
            applyMiddleware(epicMiddleware, subscribeActionMiddleware),
        )
    )

    epicMiddleware.run(rootEpic);

    return store;
}
