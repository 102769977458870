import isTokenAlmostExpired from './isTokenValid';
import api, { Headers } from './api';
import { Observable, of } from 'rxjs';
import { TokenResponse } from './requests/login';
import Response from './response';
import { map, tap, catchError } from 'rxjs/operators';

function makeAuthHeader(token: string): Headers {
  return { Authorization: `Bearer ${token}` };
}

export const getAuthHeader = (): Observable<Headers> => {
  const token = localStorage.getItem('token');

  if (token) {
    if (isTokenAlmostExpired(token)) {
      return api<Response<TokenResponse>>('/auth/refresh', 'POST', {}, { token }).pipe(
        map(response => response.data.token),
        tap(token => localStorage.setItem('token', token)),
        map(token => makeAuthHeader(token)),
        catchError(error => {
          localStorage.removeItem('token');
          throw(error);
        }),
      );
    }

    return of(makeAuthHeader(token));
  }
  return of(makeAuthHeader(''));
}
