import React, { useState } from 'react';
import { IonModal, IonList, IonToolbar, IonButtons, IonIcon, IonLoading, IonAlert, IonContent, IonHeader, IonItem, IonAvatar, IonLabel, IonTitle, IonToast } from '@ionic/react';
import './channelList.scss';
import { close } from '../../theme/icons';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { selectIsChannelProductsLoading, selectChannelProductsError } from '../../store/channelProducts/channelProducts.selectors';
import ChannelItem from './channelItem';
import newChannelIcon from '../../theme/icons/newChannel.svg';
import { selectProduct } from '../../store/products/products.selectors';
import { subscribeActionAfter } from 'redux-subscribe-action';
import { CREATE_CHANNEL_SUCCESS } from '../../store/channels/channels.action-types';
import { selectCurrentUserChannels } from '../../store/channels/channels.selectors';

interface ChannelListProps extends RouteComponentProps<{
    id: string;
}> {
    handleIsChannelListOpen: Function;
    handleIsNewChannelModalOpen: Function;
}

const ChannelList: React.FC<ChannelListProps> = ({ handleIsChannelListOpen, match, handleIsNewChannelModalOpen }) => {
    const productId = +match.params.id;
    const profileChannels = useSelector(selectCurrentUserChannels);
    const isLoading = useSelector(selectIsChannelProductsLoading)
    const error = useSelector(selectChannelProductsError, () => false);
    const [showSuccesMessage, setShowSuccessMessage] = useState(false);
    const unsubscribe = subscribeActionAfter(CREATE_CHANNEL_SUCCESS, () => { setShowSuccessMessage(true) });
    const currentProduct = useSelector(selectProduct(productId));

    return (
        <IonModal
            isOpen
            cssClass="channel-list"
        >
            <IonHeader mode="md" className="secondary-header">
                <IonToolbar className="main">
                    <IonButtons slot="start">
                    </IonButtons>
                    <IonTitle className="ion-text-center">Bewaar {currentProduct ? currentProduct.name : 'Product'}</IonTitle>
                    <IonButtons slot="end">
                        <IonIcon className="channel-list__back-button" icon={close} onClick={() => handleIsChannelListOpen()} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList mode="ios">
                    <IonToast
                        isOpen={showSuccesMessage}
                        message="Je hebt het kanaal aangemaakt."
                        onDidDismiss={() => { unsubscribe(); setShowSuccessMessage(false); }}
                        duration={2500}
                    />
                    <IonLoading
                        isOpen={isLoading}
                        message={'Please wait...'}
                    />
                    {error && (
                        <IonAlert
                            isOpen={true}
                            header={'Foutmelding'}
                            message={error.response.error.message}
                            buttons={['OK']}
                        />
                    )}
                    {profileChannels &&
                        profileChannels
                            .map((channel) => <ChannelItem key={channel.id} channel={channel} productId={productId} handleIsChannelListOpen={handleIsChannelListOpen} />
                            )}
                    <IonItem className="channel-list__item" onClick={() => handleIsNewChannelModalOpen()}>
                        <IonAvatar className="channel-list__avatar" slot="start">
                            <img src={newChannelIcon} alt="" />
                        </IonAvatar>
                        <IonLabel className="channel-list__text">
                            Nieuw Channel
                        </IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonModal >
    )
}

export default withRouter(ChannelList);