import React, { useState, createRef } from 'react';
import { IonImg } from '@ionic/react';
import { DirectusFile } from '../../http/interfaces/DirectusFile';
import "./UploadImage.scss";

type UploadImageProps = {
  currentImage: DirectusFile;
  onChange: (formData: FormData) => void;
}

const UploadImage: React.FC<UploadImageProps> = ({ currentImage, onChange }) => {
  const [imageSource, setImageSource] = useState<string>(currentImage.data.full_url);
  const reader = new FileReader();
  const buttonRef = createRef<HTMLInputElement>();

  function handleFile(input: HTMLInputElement) {
    if (!input.files || input.files.length < 1) {
      return
    }
    const file = input.files[0];

    reader.readAsDataURL(file);

    reader.onload = () => {
      setImageSource(reader.result as string);
      const form = new FormData();
      form.append('file', file, file.name);
      onChange(form);
    }
  }

  const handleInput = () => {
    if (!buttonRef || !buttonRef.current) {
      return;
    }

    buttonRef.current.click();
  }

  return (
    <>
      <IonImg src={imageSource} alt="" onClick={() => handleInput()} />
      <div className="file-upload">
        <input
          ref={buttonRef}
          type="file"
          onChange={(event) => handleFile(event.target as HTMLInputElement)}
          multiple={false} />
      </div>
    </>
  )
}

export default UploadImage;