import api from '../api';
import Response from "../response";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateChannelRequest, Channel } from '../interfaces/Channel';
import authApi from '../auth-api';
import { fixChannels } from '../fix.details';
import { Product } from '../interfaces/Products';

export interface ChannelWithProducts extends Channel {
    products: Product[];
}

interface HttpChannelWithProducts extends Channel {
    products: HttpProduct[];
}

interface HttpProduct {
    product: Product;
}

export const getChannels = (): Observable<Response<Channel[]>> => {
    return api<Response<Channel[]>>('/items/channels?fields=*,picture.id,picture.data').pipe(
        map(response => {
            return {
                ...response,
                data: fixChannels(response.data),
            }
        }),
    ); 
}

export const getChannel = (id: number): Observable<ChannelWithProducts> => {
    return api<Response<HttpChannelWithProducts>>(`/items/channels/${id}?fields=*,products.product.*`).pipe(  
        map(response => response.data as HttpChannelWithProducts),
        map(httpChannel => ({
            ...httpChannel,
            products: httpChannel.products.map(httpProduct => {
                return httpProduct.product;
            })
        }))
    ); 
}

export const createChannel = (request: CreateChannelRequest): Observable<Response<Channel>> => {
    return authApi<Response<Channel>>('/items/channels?fields=*,owner.id,picture.id,picture.data', 'POST', {}, request)
}

export const deleteChannel = (id: number): Observable<Response<void>> => {
    return authApi<Response<void>>(`/items/channels/${id}`, 'DELETE')
}