import { Reducer } from 'redux';
import { ProductsActions } from './products.actions';
import { LOAD_PRODUCTS, LOAD_PRODUCTS_SUCCESS, LOAD_PRODUCTS_FAILED } from './products.action-types';
import { Entities } from '../entities';
import { Product } from '../../http/interfaces/Products';

export interface ProductsState {
    entities: Entities<Product>;
    loading: boolean;
    loaded: boolean;
    error: Object | null;
}

const initialState: ProductsState = {
    entities: {},
    loading: false,
    loaded: false,
    error: null,
}

export const productsReducer: Reducer<ProductsState, ProductsActions> = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PRODUCTS:
            return {
                ...state,
                loading: true,
            };
        case LOAD_PRODUCTS_SUCCESS:
            const products = action.payload;
            const entities = products.reduce(
                (entities: Entities<Product>, product: Product) => {
                    return {
                        ...entities,
                        [product.id]: product
                    };
            }, {
                ...state.entities
                }
            );
            return {
                ...state,
                entities,
                data: [ ...action.payload],
                loading: false,
                loaded: true,
            }
        case LOAD_PRODUCTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}