import { Error } from '../../http/interfaces/Error';
import { REGISTER_REQUEST, REGISTER_REQUEST_SUCCESS, REGISTER_REQUEST_FAILED } from './register.action-types';

export interface RegisterRequest {
    email: string;
    full_name: string;
    username: string;
    password: string;
}

export interface RegisterRequestAction {
    type: typeof REGISTER_REQUEST,
    payload: RegisterRequest;
}

export const registerRequest = (registerRequest: RegisterRequest): RegisterRequestAction => ({
    type: REGISTER_REQUEST,
    payload: registerRequest,
});

export interface RegisterRequestSuccessAction {
    type: typeof REGISTER_REQUEST_SUCCESS,
}

export const registerRequestSuccess = (): RegisterRequestSuccessAction => ({
    type: REGISTER_REQUEST_SUCCESS,
});

export interface RegisterRequestFailedAction {
    type: typeof REGISTER_REQUEST_FAILED,
    payload: Error,
}

export const registerRequestFailed = (error: Error): RegisterRequestFailedAction => ({
    type: REGISTER_REQUEST_FAILED,
    payload: error,
});

export type RegisterActions =
    RegisterRequestAction |
    RegisterRequestSuccessAction |
    RegisterRequestFailedAction;
