import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { loadChannels } from './store/channels/channels.actions';
import { useDispatch, useSelector } from 'react-redux';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/common.scss';

import { isTokenExpired } from './http/isTokenValid';
import { loginRequestSuccess } from './store/login/login.actions';
import { loadProfile } from './store/profile/profile.actions';
import { loadFollowings } from './store/followers/followings.actions';
import { selectIsLoggedIn } from './store/login/login.selectors';
import Channels from './pages/channels/Channels';
import Search from './pages/search/Search';
import Profile from './pages/profile/Profile';
import LoginOrRegister from './pages/loginOrRegister/loginOrRegister';
import PublicProfile from './pages/profile/PublicProfile';
import Followers from './pages/followers/followers';
import ChannelSlider from './pages/channel/ChannelSlider';
import ProductDetails from './pages/product/ProductDetails';
import Login from './pages/loginOrRegister/login/login';
import Register from './pages/loginOrRegister/register/register';
import EditProfile from './pages/profile/editProfile/EditProfile';
import { swipeStyle, search, profile } from './theme/icons';
import CreateChannelPage from './pages/channels/create/CreateChannelPage';
import EditChannelPage from './pages/channels/edit/EditChannelPage';
import { loadUsers } from './store/users/users.actions';


const App: React.FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(loadProfile());
      dispatch(loadUsers());
    }
  }, [isLoggedIn, dispatch])

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      if (isTokenExpired(token)) {
        localStorage.removeItem('token');
      } else {          
        dispatch(loginRequestSuccess({token}));
      }
    }

    dispatch(loadChannels());
    dispatch(loadFollowings());
  }, [dispatch]);

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            {/* Channels Tab */}
            <Route exact path="/channels" component={Channels} />
            <Route exact path="/channels/:id" component={ChannelSlider} />
            <Route exact path="/channels/:id/products/:id" component={ProductDetails} />

            {/* Search Tab */}
            <Route exact path="/search" component={Search} />
            <Route exact path="/search/channel/:id" component={ChannelSlider} />
            <Route exact path="/search/channel/:id/products/:id" component={ProductDetails} />
            <Route exact path="/search/product/:id" component={ProductDetails} />
            <Route exact path="/search/user/:id" component={PublicProfile} />
            <Route exact path="/search/user/:id/channels/:id" component={ChannelSlider} />
            <Route exact path="/search/user/:id/channels/:id/products/:id" component={ProductDetails} />
            <Route exact path="/search/user/:id/followings/:type" component={Followers} />

            {/* Profile Tab */}
            <Route exact path="/profile" render={(props: any) => isLoggedIn ? <Profile {...props}/> : <LoginOrRegister /> } />
            <Route exact path="/profile/channels/:id" component={ChannelSlider} />
            <Route exact path="/profile/channels/:id/products/:id" component={ProductDetails} />
            <Route exact path="/profile/:id/followings/:type" component={Followers} />
            <Route exact path="/profile/create-channel" component={CreateChannelPage} />
            <Route exact path="/profile/edit-channel" component={EditChannelPage} />
            <Route exact path="/profile/edit" component={EditProfile} />
            
            {/* LoginOrRegister route */}
            <Route exact path="/account/login" component={Login} />
            <Route exact path="/account/register" component={Register} />
            
            <Redirect exact path="/" to="/channels" />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="channels" href="/channels">
              <IonIcon icon={swipeStyle} />
            </IonTabButton>
            <IonTabButton tab="search" href="/search">
              <IonIcon icon={search} />
            </IonTabButton>
            <IonTabButton tab="profile" href="/profile">
              <IonIcon icon={profile} />
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  )
};

export default App;
