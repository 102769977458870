import { LOAD_FOLLOWINGS, FOLLOW_USER, UNFOLLOW_USER } from "./followings.action-types"
import { getFollowings, postFollowUser, deleteFollowing } from "../../http/requests/followers"
import { Epic, ofType } from "redux-observable"
import { RootActions } from ".."
import { switchMap, map, catchError, withLatestFrom } from "rxjs/operators"
import { loadFollowingsSuccess, FollowingsActions, LoadFollowingsAction, FollowUserAction, followUserSuccess, followUserFailed, UnfollowUserAction, unfollowUserSuccess, unfollowUserFailed, loadFollowingsFailed } from "./followings.actions"
import { of } from "rxjs"
import { FollowUserPayload } from "../../http/interfaces/Following"
import { selectFollowingByFollowee } from "./followings.selectors"

export const loadFollowingsEpic: Epic<RootActions, FollowingsActions> = action$ => action$.pipe(
    ofType<RootActions, LoadFollowingsAction>(LOAD_FOLLOWINGS),
    switchMap(() => {
        return getFollowings().pipe(
            map(response => loadFollowingsSuccess(response.data)),
            catchError(error => of(loadFollowingsFailed(error)))
        )
    })
)

export const followUserEpic: Epic<RootActions, FollowingsActions> = action$ => action$.pipe(
    ofType<RootActions, FollowUserAction>(FOLLOW_USER),
    switchMap((action) => {
        const request: FollowUserPayload = {
            followee: action.payload,
        }
        return (
            postFollowUser(
                request
            ).pipe(
                map((response) => followUserSuccess(response.data)),
                catchError(error => of(followUserFailed(error))),
            )
        )
    }),
    catchError(error => of(followUserFailed(error))),
)

export const unfollowUserEpic: Epic<RootActions, FollowingsActions> = (action$, state$) => action$.pipe(
    ofType<RootActions, UnfollowUserAction>(UNFOLLOW_USER),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const following = selectFollowingByFollowee(action.payload)(state);
        if (!following) {
            return of(unfollowUserFailed({response: {error: {code: 0, message: 'no following'}}}));
        }
        return (
            deleteFollowing(
                following.id
            ).pipe(
                map(() => unfollowUserSuccess(following.id)),
                catchError(error => of(unfollowUserFailed(error))),
            )
        )
    }),
)

export const followingsEpics = [loadFollowingsEpic, followUserEpic, unfollowUserEpic];
