import { useSelector, useDispatch } from "react-redux";
import { selectFollowingByFollowee } from "../../store/followers/followings.selectors";
import { useState, useCallback } from "react";
import { subscribeActionAfter } from "redux-subscribe-action";
import { FOLLOW_USER_SUCCESS, UNFOLLOW_USER_SUCCESS } from "../../store/followers/followings.action-types";
import { unfollowUser, followUser } from "../../store/followers/followings.actions";

const useFollow = (userId: number) => {
  const dispatch = useDispatch();
  const isFollowing = useSelector(selectFollowingByFollowee(userId));
  const [showSuccesMessage, setShowSuccesMessage] = useState(false);
  const unsubscribe = subscribeActionAfter(isFollowing ? UNFOLLOW_USER_SUCCESS : FOLLOW_USER_SUCCESS, () => { setShowSuccesMessage(true); });

  const handleClick = useCallback(() => {
    return isFollowing ? dispatch(unfollowUser(userId)) : dispatch(followUser(userId));
  }, [dispatch, isFollowing, userId]);

  const onCloseSuccessMessage = useCallback(() => {
    unsubscribe(); 
    setShowSuccesMessage(false);
  }, [unsubscribe, setShowSuccesMessage]);

  return [
    isFollowing,
    showSuccesMessage,
    onCloseSuccessMessage,
    handleClick,
  ] as const;
}

export default useFollow;