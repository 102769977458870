import { Reducer } from "redux";
import { ChannelsActions } from "./channels.actions";
import {
    LOAD_CHANNELS,
    LOAD_CHANNELS_SUCCESS,
    LOAD_CHANNELS_FAILED,
    CREATE_CHANNEL,
    CREATE_CHANNEL_SUCCESS,
    CREATE_CHANNEL_FAILED,
    DELETE_CHANNEL,
    DELETE_CHANNEL_SUCCESS,
    DELETE_CHANNEL_FAILED,
} from "./channels.action-types";
import { Entities } from '../entities';
import { Error } from '../../http/interfaces/Error';
import { Channel } from "../../http/interfaces/Channel";

export interface ChannelsState {
    entities: Entities<Channel>;
    loading: boolean;
    loaded: boolean;
    isCreated: boolean;
    error: Error | null;
}

const initialState: ChannelsState = {
    entities: {},
    loading: false,
    loaded: false,
    isCreated: false,
    error: null,
}

export const channelsReducer: Reducer<ChannelsState, ChannelsActions> = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CHANNELS:
            return {
                ...state,
                loading: true,
            };
        case LOAD_CHANNELS_SUCCESS:
            const channels = action.payload;
            const entities = channels.reduce(
                (entities: Entities<Channel>, channel: Channel) => {
                    return {
                        ...entities,
                        [channel.id]: channel
                    };
                }, {
                ...state.entities
            }
            );
            return {
                ...state,
                entities,
                loading: false,
                loaded: true,
            }
        case LOAD_CHANNELS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CREATE_CHANNEL:
            return {
                ...state,
                loading: true,
                isCreated: false,
            };
        case CREATE_CHANNEL_SUCCESS:
            const channel = action.payload;
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [channel.id]: channel
                },
                loading: false,
                isCreated: true,
            };
        case CREATE_CHANNEL_FAILED:
            return {
                ...state,
                loading: false,
                isCreated: false,
                error: action.payload
            };
        case DELETE_CHANNEL:
            return {
                ...state,
                loading: true,
            };
        case DELETE_CHANNEL_SUCCESS:
            const channelId = action.payload.toString();
            const filteredChannels = Object.keys(state.entities)
                .filter(id => id !== channelId)
                .map(id => state.entities[id as any]);
            const filteredEntities = filteredChannels.reduce(
                (currentEntities: Entities<Channel>, filteredChannels: Channel) => {
                    return {
                        ...currentEntities,
                        [filteredChannels.id]: filteredChannels
                    };
                }, {});
            return {
                ...state,
                entities: {
                    ...filteredEntities,
                },
                loading: false
            };
        case DELETE_CHANNEL_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}