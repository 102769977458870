import { ChannelProducts } from "../../http/requests/channelProducts";
import { Reducer } from "redux";
import { ChannelProductsActions } from "./channelProducts.actions";
import {
    LOAD_CHANNEL_PRODUCTS,
    LOAD_CHANNEL_PRODUCTS_SUCCESS,
    LOAD_CHANNEL_PRODUCTS_FAILED,
    ADD_CHANNEL_PRODUCT,
    ADD_CHANNEL_PRODUCT_SUCCESS,
    ADD_CHANNEL_PRODUCT_FAILED,
} from "./channelProducts.action-types";
import { Error } from '../../http/interfaces/Error';

export interface ChannelProductsState {
    data: ChannelProducts[];
    loading: boolean;
    loaded: boolean;
    isAdded: boolean;
    error: Error | null;
}

const inititalState: ChannelProductsState = {
    data: [],
    loading: false,
    loaded: false,
    isAdded: false,
    error: null,
}

export const channelProductsReducer: Reducer<ChannelProductsState, ChannelProductsActions> = (state = inititalState, action) => {
    switch (action.type) {
        case LOAD_CHANNEL_PRODUCTS:
            return {
                ...state,
                loading: true,
            };
        case LOAD_CHANNEL_PRODUCTS_SUCCESS:
            return {
                ...state,
                data: [...action.payload],
                loading: false,
                loaded: true,
            }
        case LOAD_CHANNEL_PRODUCTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_CHANNEL_PRODUCT:
            return {
                ...state,
                loading: true,
                isAdded: false,
            };
        case ADD_CHANNEL_PRODUCT_SUCCESS:
            return {
                ...state,
                data: [
                    ...state.data,
                    action.payload
                ],
                loading: false,
                isAdded: true,
            }
        case ADD_CHANNEL_PRODUCT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
    default:
        return state;
    }
}