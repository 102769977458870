import React from 'react';
import { IonContent, IonPage, IonImg, IonButton, IonGrid, IonRow, IonCol } from "@ionic/react"
import logo from '../../theme/icons/Logo.svg';
import { Redirect } from 'react-router';
import { selectIsLoggedIn } from '../../store/login/login.selectors';
import { useSelector } from 'react-redux';

const LoginOrRegister: React.FC = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    
    if (isLoggedIn) {
        return <Redirect to="/profile" />
    }

    return (
        <IonPage>
            <IonContent className='login-wrapper'>
                <div className="login-wrapper--inner">
                    <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="10">
                                <IonImg src={logo} />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="8">
                                <IonButton
                                    className="login__button login__button--primary"
                                    expand="block"
                                    size="large"
                                    routerLink="/account/login"
                                >
                                    <span>Inloggen</span>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="8">
                                <IonButton
                                    className="login__button login__button--secondary"
                                    expand="block"
                                    size="large"
                                    routerLink="/account/register"
                                >
                                    <span>Account maken</span>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default LoginOrRegister;
