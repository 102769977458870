import React, { Fragment } from 'react';
import { IonItem, IonAvatar, IonLabel } from '@ionic/react';
import '../../pages/followers/followers.scss';
import FollowButton from '../followButton/followButton';
import { User } from '../../http/requests/users';


interface FollowersItemProps {
    user: User,
}

const FollowersItem: React.FC<FollowersItemProps> = ({ user }: FollowersItemProps) => {
    return (
        <Fragment key={user.id}>
            <IonItem className="followers-list__item">
                <IonAvatar className="followers-list__avatar" slot="start">
                    <img src={user.avatar.data.full_url} alt="" />
                </IonAvatar>
                <IonLabel className="followers-list__text">
                    @{user.full_name}
                </IonLabel>
                <FollowButton 
                    user={user}
                />
            </IonItem>
        </Fragment>
    )
}

export default FollowersItem;