import api from '../api';
import Response from "../response";
import { Observable } from 'rxjs';
import { Following, FollowUserPayload } from '../interfaces/Following';
import authApi from '../auth-api';

export const getFollowings = (): Observable<Response<Following[]>> => {
    return api<Response<Following[]>>(`/items/followings`);
}

export const postFollowUser = (request: FollowUserPayload): Observable<Response<Following>> => {
    return authApi<Response<Following>>('/items/followings', 'POST', {}, request);
}

export const deleteFollowing = (id: number): Observable<Response<Following>> => {
    return authApi<Response<Following>>(`/items/followings/${id}`, 'DELETE');
}