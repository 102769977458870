import { TokenResponse } from '../../http/requests/login';
import { Error } from '../../http/interfaces/Error';
import { LOGIN_REQUEST, LOGIN_REQUEST_SUCCESS, LOGIN_REQUEST_FAILED } from './login.action-types';

export interface LoginRequest {
    email: string;
    password: string;
}

export interface LoginRequestAction {
    type: typeof LOGIN_REQUEST,
    payload: LoginRequest;
}

export const loginRequest = (email: string, password: string): LoginRequestAction => ({
    type: LOGIN_REQUEST,
    payload: { email, password },
});

export interface LoginRequestSuccessAction {
    type: typeof LOGIN_REQUEST_SUCCESS,
    payload: TokenResponse;
}

export const loginRequestSuccess = (tokenObject: TokenResponse): LoginRequestSuccessAction => ({
    type: LOGIN_REQUEST_SUCCESS,
    payload: tokenObject,
})

export interface LoginRequestFailedAction {
    type: typeof LOGIN_REQUEST_FAILED,
    payload: Error,
}

export const loginRequestFailed = (error: Error): LoginRequestFailedAction => ({
    type: LOGIN_REQUEST_FAILED,
    payload: error,
})

export type LoginActions =
    LoginRequestAction |
    LoginRequestSuccessAction |
    LoginRequestFailedAction;