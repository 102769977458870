import api, { RequestType, Headers } from './api';
import { getAuthHeader } from './auth-headers';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const authApi = <T>(endpoint: string, method: RequestType = 'GET', customHeaders?: Headers, body?: any): Observable<T> => {
  return getAuthHeader().pipe(
    switchMap((authHeaders) => api<T>(endpoint, method as RequestType, { ...authHeaders, ...customHeaders }, body))
  );
}

export default authApi;
