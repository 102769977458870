import { IonButton, IonIcon, CreateAnimation } from "@ionic/react";
import { add } from "ionicons/icons";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Product } from "../../../http/interfaces/Products";
import "./lockedProduct.scss";

interface LockedProductProps extends RouteComponentProps<{}> {
    lockedProduct: Product;
    unlockProduct: () => void;
}

const LockedProduct: React.FC<LockedProductProps> = ({
    lockedProduct,
    unlockProduct,
    match,
    history,
}) => {
    function handleOpenProductClick(event: React.MouseEvent, id: number) {
        event.stopPropagation();
        event.preventDefault();
        openProduct(id);
    }

    function openProduct(id: number) {
        let url = `${match.url}/products/${id}`;
        history.push(url);
    }

    if (lockedProduct.id < 1) {
        return (
            <div
                className="locked-product-wrapper"
                onClick={() => unlockProduct()}
            ></div>
        );
    }

    return (
        <div className="locked-product-wrapper" onClick={() => unlockProduct()}>
            <div className="overlay">
                <CreateAnimation
                    play
                    iterations={1}
                    duration={500}
                    keyframes={[
                        { offset: 0, opacity: "0" },
                        { offset: 0.5, opacity: "0.3" },
                        { offset: 1, opacity: "0.6" },
                    ]}
                >
                    <IonButton
                        className="action-button"
                        shape="round"
                        color="dark"
                        onClick={(event) =>
                            handleOpenProductClick(event, lockedProduct.id)
                        }
                    >
                        <IonIcon icon={add}></IonIcon>
                    </IonButton>
                </CreateAnimation>
                <CreateAnimation
                    play
                    iterations={1}
                    duration={4000}
                    keyframes={[
                        {
                            offset: 0,
                            opacity: "0",
                            transform: "translateY(0px)",
                        },
                        {
                            offset: 0.1,
                            opacity: "1",
                            transform: "translateY(0px)",
                        },
                        {
                            offset: 0.8,
                            opacity: "1",
                            transform: "translateY(0px)",
                        },
                        {
                            offset: 1,
                            opacity: "0",
                            transform: "translateY(5px)",
                        },
                    ]}
                >
                    <div className="productLabel">
                        {lockedProduct.name} &euro;{lockedProduct.price}
                    </div>
                </CreateAnimation>
            </div>
        </div>
    );
};

export default withRouter(LockedProduct);
