import React from 'react';
import { IonButton, IonCard, IonGrid, IonRow, IonCol, IonAvatar, IonLabel, IonModal } from '@ionic/react';
import { User } from '../../http/requests/users';
import './friendsModal.scss';

interface FriendsButtonProps {
    user: User,
    onUnfollow: () => void,
    onClose: () => void,
    onDidmiss: () => void,
}

const FriendsModal: React.FC<FriendsButtonProps> = ({ user, onUnfollow, onClose, onDidmiss }) => {
    return (
        <IonModal 
            isOpen
            cssClass="friends-modal__overlay"
            onDidDismiss={() => onDidmiss()}
            mode='ios'
        >
            <IonCard className="friends-modal">
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonAvatar className="friends-modal__avatar friends-modal__avatar--centered">
                                <img
                                    src={user.avatar.data.full_url}
                                    alt=""
                                />
                            </IonAvatar>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonLabel className="friends-modal__text">
                                @{user.full_name}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            Wil je deze persoon niet meer volgen?
                    </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton className="follow-button ion-justify-content-center" shape="round" onClick={() => onUnfollow()}>
                                Ontvolgen
                        </IonButton>
                        </IonCol>
                        <IonCol className="ion-text-center">
                            <IonButton className="follow-button follow-button--cancel" shape="round" onClick={() => onClose()}>
                                Annuleren
                        </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCard>
        </IonModal>
    )
}

export default FriendsModal;