import { RootState } from '..';
import { Error } from '../../http/interfaces/Error';
import { RegisterState } from './register.reducer';

export const selectRegisterState = (state: RootState): RegisterState => {
    return state.register;
}

export const selectIsRegisteredLoading = (state: RootState):boolean => {
    return selectRegisterState(state).loading;
}

export const selectIsRegistered = (state: RootState): boolean => {
    return selectRegisterState(state).isRegistered;
}

export const selectRegisterError = (state: RootState): Error | null => {
    return selectRegisterState(state).error;
}
