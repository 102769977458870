import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonSlide, IonSlides, IonTitle, IonToolbar, IonToast, IonModal } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ShareButton from '../../components/shareButton/shareButton';
import { selectProduct } from '../../store/products/products.selectors';
import { backArrow, save } from '../../theme/icons';
import './ProductDetails.scss';
import ChannelList from '../../components/channelList/channelList';
import { selectIsChannelProductAdded } from '../../store/channelProducts/channelProducts.selectors';
import { ADD_CHANNEL_PRODUCT_SUCCESS } from '../../store/channelProducts/channelProducts.action-types';
import { subscribeActionAfter } from 'redux-subscribe-action';
import CreateChannel from '../../components/createChannel/CreateChannel';
import { selectisChannelCreated } from '../../store/channels/channels.selectors';
import { selectIsLoggedIn } from '../../store/login/login.selectors';
import { loadProfile } from '../../store/profile/profile.actions';
import { selectProductGender } from '../../store/products/products.selectors';
import { loadChannelProducts } from '../../store/channelProducts/channelProducts.actions';

interface ProductProps extends RouteComponentProps<{
  id: string;
}> {
  handleIsChannelListOpen: Function;
}

const sliderOptions = {
  loop: true,
  // slidesPerView: 1,
  centeredSlides: true,
  // pager: true,
}

const ProductDetails: React.FC<ProductProps> = ({ match, history }) => {
  const singleProduct = useSelector(selectProduct(+match.params.id));
  const [showChannelList, setShowChannelList] = useState<boolean>(false);
  const isChannelProductAdded = useSelector(selectIsChannelProductAdded);
  const [showSuccesMessage, setShowSuccessMessage] = useState(false);
  const unsubscribe = subscribeActionAfter(ADD_CHANNEL_PRODUCT_SUCCESS, () => { setShowSuccessMessage(true) });
  const [isNewChannelModalOpen, setisNewChannelModalOpen] = useState(false);
  const isCreated = useSelector(selectisChannelCreated)
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const productId = +match.params.id;
  const selectTheProductGender = useSelector(selectProductGender(productId));


  useEffect(() => {
    if (isChannelProductAdded) {
      dispatch(loadChannelProducts());
      dispatch(loadProfile());
      unsubscribe();
    }
  }, [isChannelProductAdded, unsubscribe, dispatch])

  useEffect(() => {
    if (isCreated) {
      setisNewChannelModalOpen(false)
    }
  }, [isCreated])

  function redirectToLoginRegisterPage() {
    history.push('/profile');
  }

  return (
    <IonPage className="product-details">
      <IonHeader mode="md" className="secondary-header">
        <IonToolbar className="main">
          <IonButtons slot="start">
            <IonBackButton className="back-button" color="warning" mode="ios" icon={backArrow} text="" defaultHref="/" />
          </IonButtons>
          <IonTitle className="ion-text-center">{singleProduct ? singleProduct.name : 'Product'}</IonTitle>
          <ShareButton title={singleProduct ? singleProduct.name : 'Product'} />
        </IonToolbar>
      </IonHeader>
      <IonContent className="main">
        <IonToast
          isOpen={showSuccesMessage}
          message="Het product is aan je kanaal toegevoegd!"
          onDidDismiss={() => setShowSuccessMessage(false)}
          duration={2500}
        />
        {isNewChannelModalOpen &&
          <IonModal
            isOpen
            onDidDismiss={() => setisNewChannelModalOpen(false)}
            mode='ios'
          >
            <CreateChannel
              gender={selectTheProductGender}
              onChannelCreated={() => setisNewChannelModalOpen(false)}
            />
          </IonModal>
        }
        {showChannelList && (
          <ChannelList
            handleIsChannelListOpen={() => setShowChannelList(false)}
            handleIsNewChannelModalOpen={() => setisNewChannelModalOpen(true)}
          />
        )}
        {singleProduct && (
          <>
            <IonSlides pager={true} options={sliderOptions}>
              <IonSlide>
                <img src={singleProduct.img_url} alt="" />
              </IonSlide>
              {singleProduct.alternate_img_url && (
              <IonSlide>
                <img src={singleProduct.alternate_img_url} alt="" />
              </IonSlide>
              )}
            </IonSlides>
            <div className="product-details__content">
              <IonGrid>
                <IonRow class="ion-justify-content-center" >
                  <IonCol className="ion-text-center product-details__description">
                    {singleProduct.description}
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <IonCol className="ion-text-center product-details__price">
                    <span className="product-details__price product-details__price--new">
                      &euro;{singleProduct.price}
                    </span>
                    <span className="product-details__price product-details__price--old">
                      &euro;{singleProduct.price}
                    </span>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <IonCol class="ion-align-self-end" size="7">
                    <IonButton className="product-details__button product-details__button--purchase" expand="block" href={singleProduct.shop_url}>
                      <span className="product-details__button--text">
                        Koop bij {singleProduct.brand}
                      </span>
                    </IonButton>
                  </IonCol>
                  <IonCol size="5">
                    <IonButton className="product-details__button product-details__button--save" expand="block" onClick={() => isLoggedIn ? setShowChannelList(true) : redirectToLoginRegisterPage()}>
                      <IonIcon className="product-details__button product-details__button--icon" icon={save} />
                      Bewaar
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </>
        )}
      </IonContent>
    </IonPage >
  )
}

export default ProductDetails;
