import { ajax } from 'rxjs/ajax';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const baseUrl = process.env.REACT_APP_API_URL || "";

export type RequestType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
export type Headers = { [key: string]: string };

const api = <T>(endpoint: string, method: RequestType = 'GET', customHeaders: Headers = {}, body?: any): Observable<T> => {
  const extendedHeaders = (body instanceof FormData) ? {} : { 'Content-Type': 'application/json' };
  
  return ajax({
    url: `${baseUrl}${endpoint}`,
    method,
    headers: {
      Accept: 'application/json',
      ...customHeaders,
      ...extendedHeaders,
    },
    body: body || undefined,
  }).pipe(
    map(ajaxResponse => ajaxResponse.response as T)
  );
};

export default api;
