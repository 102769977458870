import { User } from '../../http/requests/users';
import { EditProfilePayload } from '../../http/interfaces/Profile';
import { Error } from '../../http/interfaces/Error';
import { LOAD_PROFILE, LOAD_PROFILE_SUCCESS, LOAD_PROFILE_FAILED, EDIT_PROFILE, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAILED } from './profile.action-types';

export interface LoadProfileAction {
    type: typeof LOAD_PROFILE,
}

export const loadProfile = (): LoadProfileAction => ({
    type: LOAD_PROFILE
});

export interface LoadProfileSuccessAction {
    type: typeof LOAD_PROFILE_SUCCESS,
    payload: User,
}

export const loadProfileSuccess = (profile: User): LoadProfileSuccessAction => ({
    type: LOAD_PROFILE_SUCCESS,
    payload: profile,
});

export interface LoadProfileFailedAction {
    type: typeof LOAD_PROFILE_FAILED,
    payload: Error,
}

export const loadProfileFailed = (error: Error): LoadProfileFailedAction => ({
    type: LOAD_PROFILE_FAILED,
    payload: error,
})

export interface EditProfileRequestAction {
    type: typeof EDIT_PROFILE,
    payload: EditProfilePayload;
}

export const editProfileRequest = (EditProfilePayload: EditProfilePayload): EditProfileRequestAction => ({
    type: EDIT_PROFILE,
    payload: EditProfilePayload,
})

export interface EditProfileRequestSuccessAction {
    type: typeof EDIT_PROFILE_SUCCESS,
}

export const editProfileRequestSuccess = (): EditProfileRequestSuccessAction => ({
    type: EDIT_PROFILE_SUCCESS,
})

export interface EditProfileRequestFailedAction {
    type: typeof EDIT_PROFILE_FAILED,
    payload: Error,
}

export const editProfileRequestFailed = (error: Error): EditProfileRequestFailedAction => ({
    type: EDIT_PROFILE_FAILED,
    payload: error,
})

export type ProfileActions =
    LoadProfileAction |
    LoadProfileSuccessAction |
    LoadProfileFailedAction |
    EditProfileRequestAction |
    EditProfileRequestSuccessAction |
    EditProfileRequestFailedAction;
    