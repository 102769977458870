import { IonAlert, IonAvatar, IonBackButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonLoading, IonPage, IonRow, IonText, IonTextarea, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import UploadImage from '../../../components/upload-image/UploadImage';
import { EditProfilePayload } from '../../../http/interfaces/Profile';
import { User } from '../../../http/requests/users';
import { selectIsLoggedIn } from '../../../store/login/login.selectors';
import { editProfileRequest, loadProfile } from '../../../store/profile/profile.actions';
import { selectProfile, selectProfileLoading, selectProfileError, selectisProfileEdited } from '../../../store/profile/profile.selectors';
import { backArrow, confirm } from '../../../theme/icons';
import './EditProfile.scss';

type EditProfileFormField = keyof EditProfilePayload;

function getProfile(profile: User | null): EditProfilePayload {
    if (!profile) {
        return { id: 0, full_name: '', username: '', website: '', bio: '' };
    }

    return {
        id: profile.id,
        full_name: profile.full_name,
        username: profile.username,
        website: profile.website,
        bio: profile.bio,
    };
}

const EditProfile: React.FC = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isProfileEdited = useSelector(selectisProfileEdited);
    const error = useSelector(selectProfileError, () => false);
    const profile = useSelector(selectProfile);
    const isLoading = useSelector(selectProfileLoading);
    const [editProfile, setEditProfile] = useState<EditProfilePayload>(getProfile(profile));

    useIonViewWillEnter(() => {
        dispatch(loadProfile());
    });

    useEffect(() => {
        if (profile) {
            setEditProfile(getProfile(profile));
        }
    }, [profile]);

    function handleFile(fileFieldName: EditProfileFormField, form: FormData) {
        setEditProfile({
            ...editProfile,
            [fileFieldName]: form,
        })
    }

    const handleEditProfile = () => {
        dispatch(editProfileRequest(editProfile))
    };

    const handleInputOnChange = (event: CustomEvent) => {
        if (!event.target) {
            return;
        }

        const key: EditProfileFormField = (event.target as HTMLIonInputElement).name as EditProfileFormField;
        const value: any = (event.target as HTMLInputElement).value;
        setEditProfile({ ...editProfile, [key]: value });
    };

    if (!isLoggedIn) {
        return <Redirect to="/" />
    }

    if (isProfileEdited) {
        return <Redirect to="/profile" />
    }

    return (
        <IonPage>
            <IonHeader mode="md" className="secondary-header">
                <IonToolbar className="main">
                    <IonButtons slot="start">
                        <IonBackButton className="back-button" mode="ios" color="warning" icon={backArrow} text="" defaultHref="/"></IonBackButton>
                    </IonButtons>
                    <IonTitle className="ion-text-center">Profiel bewerken</IonTitle>
                    <IonButtons slot="end">
                        <IonIcon className="confirm-button" icon={confirm} onClick={() => handleEditProfile()} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {error && (
                    <IonAlert
                        isOpen={true}
                        header={'Foutmelding'}
                        message={error.response.error.message}
                        buttons={['OK']}
                    />
                )}
                <IonLoading
                    isOpen={isLoading}
                    message={'Please wait...'}
                />
                {profile && (
                    <IonGrid className="ion-padding-top ion-padding-bottom edit-profile">
                        <section className="edit-profile edit-profile__header">
                            <IonRow className="ion-justify-content-center">
                                <IonCol class="ion-align-self-end" size="5">
                                    <IonText className="edit-profile__text">
                                        Profielfoto
                                    </IonText>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText className="edit-profile__text edit-profile__text--centered">
                                        Achtergrond
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="5">
                                    <IonAvatar className="edit-profile__avatar">
                                        <UploadImage currentImage={profile.avatar} onChange={(form) => handleFile('avatar', form)} />
                                    </IonAvatar>
                                </IonCol>
                                <IonCol size="7">
                                    <div className="edit-profile__background">
                                        <UploadImage currentImage={profile.profile_background} onChange={(form) => handleFile('profile_background', form)} />
                                    </div>
                                </IonCol>
                            </IonRow>
                        </section>
                        <IonRow className="ion-padding-top ion-justify-content-center">
                            <IonCol size="10">
                                <IonInput
                                    className="ion-text-center input"
                                    type="text"
                                    placeholder="Naam"
                                    value={editProfile.full_name}
                                    name='full_name'
                                    onIonInput={(event) => handleInputOnChange(event)}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-center">
                            <IonCol size="10">
                                <IonInput
                                    className="ion-text-center input"
                                    type="text"
                                    placeholder="Gebruikersnaam"
                                    value={editProfile.username}
                                    name='username'
                                    onIonInput={(event) => handleInputOnChange(event)}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-center">
                            <IonCol size="10">
                                <IonInput
                                    className="ion-text-center input"
                                    type="url"
                                    placeholder="Website"
                                    value={editProfile.website}
                                    name="website"
                                    onIonInput={(event) => handleInputOnChange(event)}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-center">
                            <IonCol size="10">
                                <IonTextarea
                                    className="ion-text-center input input__textarea"
                                    placeholder="Bio"
                                    value={editProfile.bio}
                                    name='bio'
                                    onIonInput={(event) => handleInputOnChange(event)}
                                    autoGrow
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EditProfile;
