import { LOAD_PRODUCTS, LOAD_PRODUCTS_SUCCESS, LOAD_PRODUCTS_FAILED } from './products.action-types';
import { Product } from '../../http/interfaces/Products';

export interface LoadProductsAction {
    type: typeof LOAD_PRODUCTS,
}

export const loadProducts = (): LoadProductsAction => ({
    type: LOAD_PRODUCTS,
});

export interface LoadProductsSuccessAction {
    type: typeof LOAD_PRODUCTS_SUCCESS,
    payload: Product[],
}

export const loadProductsSuccess = (products: Product[]): LoadProductsSuccessAction => ({
    type: LOAD_PRODUCTS_SUCCESS,
    payload: products,
});

export interface LoadProductsFailedAction {
    type: typeof LOAD_PRODUCTS_FAILED,
    payload: Object,
}

export const loadProductsFailed = (error: Object): LoadProductsFailedAction => ({
    type: LOAD_PRODUCTS_FAILED,
    payload: error,
});

export type ProductsActions = 
    LoadProductsAction |
    LoadProductsSuccessAction |
    LoadProductsFailedAction;