import { TokenResponse } from '../../http/requests/login';
import { Reducer } from 'redux';
import { LoginActions } from './login.actions';
import { LOGIN_REQUEST, LOGIN_REQUEST_SUCCESS, LOGIN_REQUEST_FAILED } from './login.action-types';
import { Error } from '../../http/interfaces/Error';

export interface LoginState {
    data: TokenResponse;
    loading: boolean;
    loggedIn: boolean;
    error: Error | null;
}

const initialState: LoginState = {
    data: {
        token: ''
    },
    loading: false,
    loggedIn: false,
    error: null,
}

export const loginReducer: Reducer<LoginState, LoginActions> = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                loggedIn: true,
                error: null,
            }
        case LOGIN_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}