import React, { Fragment } from 'react';
import { IonItem, IonAvatar, IonLabel } from '@ionic/react';
import '../../pages/followers/followers.scss';
import FollowButton from '../followButton/followButton';
import { User } from '../../http/requests/users';
import { useSelector } from 'react-redux';
import { selectIsMeFollowingUser, selectIsUserFollowingMe } from '../../store/followers/followings.selectors';


interface FolloweesItemProps {
    user: User,
}

const FolloweesItem: React.FC<FolloweesItemProps> = ({ user }) => {
    const meFollowsUser = useSelector(selectIsMeFollowingUser(user.id));
    const userFollowsMe = useSelector(selectIsUserFollowingMe(user.id));
    
    return (
        <Fragment key={user.id}>
            <IonItem className="followers-list__item">
                <IonAvatar className="followers-list__avatar" slot="start">
                    <img src={user.avatar.data.full_url} alt="" />
                </IonAvatar>
                <IonLabel className="followers-list__text ion-text-wrap">
                    @{user.username}
                    {(meFollowsUser && !userFollowsMe) && (
                    <p className="followers-list__text--small">
                        Volgt niet terug  
                    </p>
                )}
                </IonLabel>
                <FollowButton 
                    user={user}
                />
            </IonItem>
        </Fragment>
    )
}

export default FolloweesItem;