import authApi from '../auth-api';
import Response from "../response";
import { Observable } from 'rxjs';
import { DirectusFile } from '../interfaces/DirectusFile';
import { map } from 'rxjs/operators';
import { fixUsers } from '../fix.details';
import { Channel } from '../interfaces/Channel';

export interface User {
    id: number;
    full_name: string;
    email: string;
    avatar: DirectusFile;
    bio: string;
    username: string;
    website: string;
    profile_background: DirectusFile;
    channels: Channel[];
}

const userQueryParams = '?fields=*,channels.*,channels.picture.*,avatar.id,avatar.data,profile_background.id,profile_background.data';

export const getUsers = (): Observable<Response<User[]>> => {
    return authApi<Response<User[]>>(`/users${userQueryParams}`).pipe(
        map(response => {
            return {
                ...response,
                data: fixUsers(response.data),
            }
        }),
    ); 
}
