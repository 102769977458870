import { Epic, ofType } from 'redux-observable';
import { RootActions } from '..';
import { ChannelProductsActions, loadChannelProductsSuccess, AddChannelProductAction, addChannelProductSuccess, addChannelProductFailed } from './channelProducts.actions';
import { LOAD_CHANNEL_PRODUCTS, ADD_CHANNEL_PRODUCT } from './channelProducts.action-types';
import { getChannelProducts, postChannelProduct } from '../../http/requests/channelProducts';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export const loadChannelProductsEpic: Epic<RootActions, ChannelProductsActions> = action$ => action$.pipe(
    ofType(LOAD_CHANNEL_PRODUCTS),
    switchMap(() => {
        return getChannelProducts().pipe(
            map(response => loadChannelProductsSuccess(response.data))
        )
    })
)

export const addChannelProduct: Epic<RootActions, ChannelProductsActions> = action$ => action$.pipe(
    ofType<RootActions, AddChannelProductAction>(ADD_CHANNEL_PRODUCT),
    switchMap((action) => {
        return (
            postChannelProduct(
                action.payload
            ).pipe(
                map((response) => addChannelProductSuccess(response.data)),
                catchError(error => of(addChannelProductFailed(error))),
            )
        )
    }),
    catchError(error => of(addChannelProductFailed(error))),
)

export const channelProductsEpics = [loadChannelProductsEpic, addChannelProduct];
