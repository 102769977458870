import jwtDecode from 'jwt-decode';

interface JwtTokenInfo {
  id: number;
  exp: number;
  type: string;
  key: string;
  project: string;
}

export const expirationTreshold = 5 * 60; // 5 minutes in seconds

export function getTokenLifeTimeLeft(token: string): number {
  const decodedToken: JwtTokenInfo = jwtDecode(token);
  // all time in seconds
  const tokenExpirationTime: number = decodedToken.exp;
  const currentTime = Math.floor(new Date().getTime() / 1000);
  return (tokenExpirationTime - currentTime);
}

export default function isTokenAlmostExpired(token: string): boolean {
  return getTokenLifeTimeLeft(token) < expirationTreshold;
}

export function isTokenExpired(token: string): boolean {
  return getTokenLifeTimeLeft(token) <= 0
}