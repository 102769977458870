import { User } from '../../http/requests/users';
import { Reducer } from 'redux';
import { UsersActions } from './users.actions';
import { LOAD_USERS, LOAD_USERS_SUCCESS, LOAD_USERS_FAILED } from './users.action-types';

export interface UsersState {
    data: User[];
    loading: boolean;
    loaded: boolean;
    error: Object | null;
}

const initialState: UsersState = {
    data: [],
    loading: false,
    loaded: false,
    error: null,
}

export const usersReducer: Reducer<UsersState, UsersActions> = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_USERS:
            return {
                ...state,
                loading: true,
            };
        case LOAD_USERS_SUCCESS:
            return {
                ...state,
                data: [ ...action.payload],
                loading: false,
                loaded: true,
            }
        case LOAD_USERS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}