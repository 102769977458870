import authApi from '../auth-api';
import Response from "../response";
import { User } from './users';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { fixUser } from '../fix.details';

export interface EditProfileRequest {
    id: number;
    full_name?: string;
    username?: string;
    website?: string;
    bio?: string;
    avatar?: number;
    profile_background?: number;
}

const userQueryParams = '?fields=*,channels.*,channels.picture.*,avatar.id,avatar.data,profile_background.id,profile_background.data';

export const getProfile = (): Observable<Response<User>> => {
    return authApi<Response<User>>(`/users/me${userQueryParams}`).pipe(
        map(response => {
            return {
                ...response,
                data: fixUser(response.data),
            }
        }),
    );
}

export const getPublicProfile = (id: number): Observable<Response<User>> => {
    return authApi<Response<User>>(`/users/${id}${userQueryParams}`).pipe(
        map(response => {
            return {
                ...response,
                data: fixUser(response.data),
            }
        }),
    );
}

export const patchProfile = (request: EditProfileRequest): Observable<Response<User>> => {
    return authApi<Response<User>>(`/users/${request.id}`, 'PATCH', {}, request);
}
