import { Epic, ofType } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';
import { RootActions } from '..';
import { getProfile, patchProfile, EditProfileRequest } from '../../http/requests/profile';
import { LOAD_PROFILE, EDIT_PROFILE } from './profile.action-types';
import { loadProfileSuccess, ProfileActions, editProfileRequestSuccess, editProfileRequestFailed, EditProfileRequestAction } from './profile.actions';
import { of, Observable, forkJoin } from 'rxjs';
import { EditProfilePayload } from '../../http/interfaces/Profile';
import { uploadFile } from '../../http/requests/file';

export const loadProfileEpic: Epic<RootActions, ProfileActions> = action$ => action$.pipe(
    ofType(LOAD_PROFILE),
    switchMap(() => {
        return getProfile().pipe(
            map(response => loadProfileSuccess(response.data))
        )
    })
)

function editProfilePayloadToRequest(editProfilePayload: EditProfilePayload): Observable<EditProfileRequest> {
    if (!!editProfilePayload.avatar && !editProfilePayload.profile_background) {
        return uploadFile(editProfilePayload.avatar).pipe(
            map(response => ({
                ...editProfilePayload,
                avatar: response.data.id,
            } as EditProfileRequest))
        )
    }

    if (!editProfilePayload.avatar && !!editProfilePayload.profile_background) {
        return uploadFile(editProfilePayload.profile_background).pipe(
            map(response => ({
                ...editProfilePayload,
                profile_background: response.data.id,
            } as EditProfileRequest))
        )
    }

    if (!!editProfilePayload.avatar && !!editProfilePayload.profile_background) {
        return forkJoin(
            {
                avatar: uploadFile(editProfilePayload.avatar),
                profile_background: uploadFile(editProfilePayload.profile_background),
            }
        ).pipe(
            map(joinedRequest => ({
                ...editProfilePayload,
                avatar: joinedRequest.avatar.data.id,
                profile_background: joinedRequest.profile_background.data.id,
            }))
        )
    }

    return of({
        ...editProfilePayload,
    } as EditProfileRequest);
}

export const editProfileEpic: Epic<RootActions, ProfileActions> = action$ => action$.pipe(
    ofType<RootActions, EditProfileRequestAction>(EDIT_PROFILE),
    switchMap((action) => 
        editProfilePayloadToRequest(action.payload)
    ),
    switchMap((request) => {
        return (
            patchProfile(
                request
            ).pipe(
                map(() => editProfileRequestSuccess()),
                catchError(error => of(editProfileRequestFailed(error))),
            )
        )
    }),
    catchError(error => of(editProfileRequestFailed(error))),
)

export const profileEpics = [loadProfileEpic, editProfileEpic];
