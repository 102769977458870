import React, { useState } from 'react';
import { IonContent, IonPage, IonTitle, IonInput, IonButton, IonGrid, IonRow, IonCol, IonHeader, IonToolbar, IonButtons, IonBackButton, IonText, IonIcon, IonAlert, IonLoading } from "@ionic/react";
import insta from '../../../theme/icons/Insta.svg';
import { backArrow } from '../../../theme/icons';
import { isEmailValid, isFullNameValid, isPasswordValid, isUsernameValid } from '../../../http/validation';
import { useDispatch, useSelector } from 'react-redux';
import { selectRegisterError } from '../../../store/register/register.selectors';
import { registerRequest } from '../../../store/register/register.actions';
import { Redirect } from 'react-router';
import { selectIsLoggedIn, selectIsLoginLoading} from '../../../store/login/login.selectors';
import { RegisterRequest } from '../../../store/register/register.actions';

const Register: React.FC = () => {
    const [registrationForm, setRegistrationForm] = useState<RegisterRequest>({ full_name: '', email: '', username: '', password: '' });
    const [stepTwo, setStepTwo] = useState(false);
    const error = useSelector(selectRegisterError, () => false)
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isNextStepButtonDisabled = !isEmailValid(registrationForm.email) || !isFullNameValid(registrationForm.full_name); 
    const isSubmitButtonDisabled =!isPasswordValid(registrationForm.password) || !isUsernameValid(registrationForm.username);
    const isLoading = useSelector(selectIsLoginLoading);    

    const handleRegister = () => {
        dispatch(registerRequest(registrationForm))
    };

    const handleKeyPress = (e: any) => {
        if ((e.keyCode === 13 || e.which === 13)) {
            if (stepTwo && !isNextStepButtonDisabled) {
                setStepTwo(true);
                return;
            }
            if (stepTwo && !isSubmitButtonDisabled) {
                handleRegister();
                return;
            }
        }
    };

    const handleInputOnChange = (key: string, value: string) => {
        setRegistrationForm({ ...registrationForm, [key]: value });
    };

    if (isLoggedIn) {
        return <Redirect to="/profile" />
    }

    return (
        <IonPage>
            <IonContent className="login-wrapper login-wrapper--login">
                <IonHeader mode="md" className="secondary-header">
                    <IonToolbar className="main">
                        <IonButtons slot="start">
                            {stepTwo ? (
                                <IonIcon className="back-button back-button--bigger" color="warning" icon={backArrow} onClick={() => (setStepTwo(false))} />
                            ) : (
                                    <IonBackButton className="back-button" color="warning" mode="ios" icon={backArrow} text="" defaultHref="/" />
                                )}
                        </IonButtons>
                        <IonTitle className="ion-text-center">Account maken</IonTitle>
                        <IonButtons slot="end">
                            <IonIcon className="back-button back-button--bigger" color="warning" />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                {error && (
                    <IonAlert
                        isOpen={true}
                        header={'Foutmelding'}
                        message={error.response.error.message}
                        buttons={['OK']}
                    />
                )}
                <IonLoading
                    isOpen={isLoading}
                    message={'Please wait...'}
                />
                {!stepTwo && (
                    <IonGrid>
                        <IonRow className="ion-padding-top ion-text-center ion-justify-content-center">
                            <IonCol size="8">
                                <IonText className="login__text">
                                    Maak een account aan
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-text-center ion-justify-content-center">
                            <IonCol size="10">
                                <IonButton
                                    className="login__button login__button--primary"
                                    expand="block"
                                    size="large"
                                >
                                    <IonIcon className="login__icon" slot="start" icon={insta} />
                                    <span>Log in met Instagram</span>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-text-center ion-justify-content-center">
                            <IonCol size="8">
                                <IonText className="login__text">
                                    of...
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-center">
                            <IonCol size="10">
                                <IonInput
                                    className="ion-text-center input"
                                    type="text"
                                    name='full_name'
                                    placeholder="Naam"
                                    value={registrationForm.full_name}
                                    onIonInput={(event) => handleInputOnChange((event.target as HTMLIonInputElement).name, (event.target as HTMLInputElement).value)}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-center">
                            <IonCol size="10">
                                <IonInput
                                    className="ion-text-center input"
                                    type="email"
                                    name='email'
                                    placeholder="E-mailadres"
                                    value={registrationForm.email}
                                    onIonInput={(event) => handleInputOnChange((event.target as HTMLIonInputElement).name, (event.target as HTMLInputElement).value)}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-center">
                            <IonCol size="6">
                                <IonButton
                                    className="login__button login__button--tertiary"
                                    expand="block"
                                    size="large"
                                    onClick={() => (setStepTwo(true))}
                                    disabled={isNextStepButtonDisabled}
                                >
                                    <span>Aanmelden</span>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                )}
                {stepTwo && (
                    <IonGrid>
                        <IonRow className="ion-padding-top ion-text-center ion-justify-content-center">
                            <IonCol size="8">
                                <IonText className="login__text">
                                    Bijna klaar om te shoppen
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-center">
                            <IonCol size="10">
                                <IonInput
                                    className="ion-text-center input"
                                    type="text"
                                    name="username"
                                    placeholder="Gebruikersnaam"
                                    value={registrationForm.username}
                                    onIonInput={(event) => handleInputOnChange((event.target as HTMLIonInputElement).name, (event.target as HTMLInputElement).value)}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-center">
                            <IonCol size="10">
                                <IonInput
                                    className="ion-text-center input"
                                    type="password"
                                    name="password"
                                    placeholder="Wachtwoord"
                                    value={registrationForm.password}
                                    onIonInput={(event) => handleInputOnChange((event.target as HTMLIonInputElement).name, (event.target as HTMLInputElement).value)}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-center">
                            <IonCol size="6">
                                <IonButton
                                    className="login__button login__button--tertiary"
                                    expand="block"
                                    size="large"
                                    onClick={() => handleRegister()}
                                    disabled={isSubmitButtonDisabled}
                                >
                                    <span>Akkoord</span>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-text-center ion-justify-content-center">
                            <IonCol size="8">
                                <a className="login__link" href="/">
                                    Of beheer voorkeuren
                                </a>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                )}
            </IonContent>
        </IonPage>
    );
}

export default Register;
