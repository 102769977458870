import { RootState } from '..';
import { Error } from '../../http/interfaces/Error';
import { FollowingsState } from './followings.reducer';
import { Following } from '../../http/interfaces/Following';
import { selectProfile } from '../profile/profile.selectors';
import { selectUser } from '../users/users.selectors';
import { User } from '../../http/requests/users';

export const selectFollowingsState = (state: RootState): FollowingsState => {
    return state.followings;
}

export const selectFollowings = (state: RootState): Following[] => {
    return selectFollowingsState(state).data;
}

export const selectIsFollowingsLoading = (state: RootState): boolean => {
    return selectFollowingsState(state).loading;
}

export const selectFollowingsError = (state: RootState): Error | null => {
    return selectFollowingsState(state).error;
}

export const selectFollowers = (currentUserId: number) => (state: RootState): User[] => {
    const followings = selectFollowings(state);

    return followings
        .filter(following => following.followee === currentUserId)
        .map(following => following.follower)
        .map(userId => selectUser(userId)(state));
}

export const selectFollowees = (currentUserId: number) => (state: RootState): User[] => {
    const followees = selectFollowings(state);

    return followees
        .filter(followee => followee.follower === currentUserId)
        .map(followee => followee.followee)
        .map(userId => selectUser(userId)(state))
}

export const selectFollowingByFollowee = (followeeId: number | undefined) => (state: RootState): Following | null => {
    const currentUser = selectProfile(state);
    if (!currentUser) {
        return null;
    }
    const followerId = currentUser.id;
    const following = selectFollowings(state)
        .find(following => (following.followee === followeeId) && (following.follower === followerId));

    return following || null;
}

export const selectIsUserFollowingMe = (userId: number) => (state: RootState): boolean => {
    const followings = selectFollowings(state);
    const currentUser = selectProfile(state);
    if (!followings || !currentUser) {
        return false;
    }
    const userFollowsMe = followings.some(following => (following.followee === currentUser.id && following.follower === userId));

    return userFollowsMe;
}

export const selectIsMeFollowingUser = (userId: number) => (state: RootState): boolean => {
    const followings = selectFollowings(state);
    const currentUser = selectProfile(state);
    if (!followings || !currentUser) {
        return false;
    }
    const meFollowsUser = followings.some(following => (following.follower === currentUser.id && following.followee === userId));

    return meFollowsUser;
}

export const selectIsFriends = (userId: number) => (state: RootState) => {
    const isUserFollowingMe: boolean = selectIsUserFollowingMe(userId)(state);
    const isMeFollowingUser: boolean = selectIsMeFollowingUser(userId)(state);

    return isUserFollowingMe && isMeFollowingUser;
}

export const selectAmountOfFollowees = (userId: number) => (state: RootState): number => {
    const followings = selectFollowings(state);
    if (!followings) {
        return 0
    }
    const userFollowsMe = followings.filter(following => (following.follower === userId));
    const followersAmount = userFollowsMe.length
    return followersAmount
}

export const selectAmountOfFollowers = (userId: number) => (state: RootState): number => {
    const followees = selectFollowings(state);
    if (!followees) {
        return 0
    }
    const userFollowees = followees.filter(followee => (followee.followee === userId));
    const followeeAmount = userFollowees.length
    return followeeAmount
}