import React, { useState } from "react";
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonIcon,
    IonBackButton,
    IonContent,
    IonList,
    IonToast,
    IonLoading,
    IonAlert,
    IonItem,
    IonAvatar,
    IonLabel,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { backArrow } from "../../../theme/icons";
import { useSelector, useDispatch } from "react-redux";
import {
    selectCurrentUserChannels,
    selectChannelError,
} from "../../../store/channels/channels.selectors";
import "./EditChannelPage.scss";
import { selectIsChannelProductsLoading } from "../../../store/channelProducts/channelProducts.selectors";
import { edit } from "../../../theme/icons";
import { close } from "ionicons/icons";
import { deleteChannelRequest } from "../../../store/channels/channels.actions";
import { subscribeActionAfter } from "redux-subscribe-action";
import { DELETE_CHANNEL_SUCCESS } from "../../../store/channels/channels.action-types";
interface EditChannelPageProps
    extends RouteComponentProps<{
        id: string;
    }> {}

const EditChannelPage: React.FC<EditChannelPageProps> = () => {
    const dispatch = useDispatch();
    const profileChannels = useSelector(selectCurrentUserChannels);
    const error = useSelector(selectChannelError, () => false);
    const isLoading = useSelector(selectIsChannelProductsLoading);
    const [showSuccesMessage, setShowSuccessMessage] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const unsubscribe = subscribeActionAfter(DELETE_CHANNEL_SUCCESS, () => {
        setShowSuccessMessage(true);
    });
    const [currentChannelId, setCurrentChannelId] = useState(0);
    const [currentChannelName, setCurrentChannelName] = useState("Channel");

    const handleDeleteChannel = (id: number) => {
        dispatch(deleteChannelRequest(id));
        unsubscribe();
    };

    return (
        <IonPage>
            <IonHeader mode="md" className="secondary-header">
                <IonToolbar className="main">
                    <IonButtons slot="start">
                        <IonBackButton
                            className="back-button"
                            color="warning"
                            mode="ios"
                            icon={backArrow}
                            text=""
                            defaultHref="/"
                        />
                    </IonButtons>
                    <IonTitle className="ion-text-center">
                        Bewerk channel
                    </IonTitle>
                    <IonButtons slot="end"></IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="Edit-channel">
                <IonList mode="ios">
                    <IonAlert
                        isOpen={showDeleteAlert}
                        onDidDismiss={() => setShowDeleteAlert(false)}
                        header={`${currentChannelName} verwijderen`}
                        message={`Weet je zeker dat je ${currentChannelName} wilt verwijderen?`}
                        buttons={[
                            {
                                text: "Terug",
                                role: "cancel",
                            },
                            {
                                text: "Verwijderen",
                                role: "delete",
                                handler: () =>
                                    handleDeleteChannel(currentChannelId),
                            },
                        ]}
                    />
                    <IonToast
                        isOpen={showSuccesMessage}
                        message="Je hebt het kanaal verwijderd."
                        onDidDismiss={() => {
                            setShowSuccessMessage(false);
                        }}
                        duration={2500}
                    />
                    <IonLoading isOpen={isLoading} message={"Please wait..."} />
                    {error && (
                        <IonAlert
                            isOpen={true}
                            header={"Foutmelding"}
                            message={error.response.error.message}
                            buttons={["OK"]}
                        />
                    )}
                    {profileChannels &&
                        profileChannels.map((channel) => (
                            <IonItem
                                className="channel-list__item"
                                key={channel.id}
                            >
                                <IonAvatar
                                    className="channel-list__avatar"
                                    slot="start"
                                >
                                    <img
                                        src={channel.picture.data.full_url}
                                        alt=""
                                    />
                                </IonAvatar>
                                <IonLabel className="channel-list__text">
                                    {channel.name}
                                </IonLabel>
                                <IonIcon
                                    className="channel-list__icon"
                                    icon={edit}
                                />
                                <IonIcon
                                    className="channel-list__icon--delete"
                                    icon={close}
                                    onClick={() => [
                                        setCurrentChannelId(channel.id),
                                        setCurrentChannelName(channel.name),
                                        setShowDeleteAlert(true),
                                    ]}
                                />
                            </IonItem>
                        ))}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default EditChannelPage;
