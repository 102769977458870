import { Reducer } from 'redux';
import { RegisterActions } from './register.actions'
import { REGISTER_REQUEST, REGISTER_REQUEST_SUCCESS, REGISTER_REQUEST_FAILED } from './register.action-types';
import { Error } from '../../http/interfaces/Error';

export interface RegisterState {
    loading: boolean;
    isRegistered: boolean;
    error: Error | null;
}

const initialState: RegisterState = {
    loading: false,
    isRegistered: false,
    error: null,
}

export const registerReducer: Reducer<RegisterState, RegisterActions> = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case REGISTER_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedIn: true,
                error: null,
            }
        case REGISTER_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}
