import { RootState } from "..";
import { ProfileState } from "./profile.reducer";
import { User } from "../../http/requests/users";
import { Error } from '../../http/interfaces/Error';

export const selectProfileState = (state: RootState): ProfileState => {
    return state.profile;
}

export const selectProfileLoading = (state: RootState): boolean => {
    return selectProfileState(state).loading;
}

export const selectProfile = (state: RootState): User | null => {
    return selectProfileState(state).data;
}

export const selectIsItMe = (userId: number) => (state: RootState): boolean => {
    const profile = selectProfile(state);
    if (!profile) {
        return false;
    }
    return profile.id === userId;
}

export const selectProfileError = (state: RootState): Error | null => {
    return selectProfileState(state).error;
}

export const selectisProfileEdited = (state: RootState): boolean => {
    return selectProfileState(state).isEdited;
}
    