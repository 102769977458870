import { IonAlert, IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonLoading, IonPage, IonRow, IonText, IonTitle, IonToolbar } from "@ionic/react";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { isEmailValid, isPasswordValid } from '../../../http/validation';
import { loginRequest, LoginRequest } from '../../../store/login/login.actions';
import { selectError, selectIsLoggedIn, selectIsLoginLoading } from '../../../store/login/login.selectors';
import { backArrow } from '../../../theme/icons';
import insta from '../../../theme/icons/Insta.svg';
import './login.scss';

const Login: React.FC = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const error = useSelector(selectError, () => false);
    const [loginForm, setLoginForm] = useState<LoginRequest>({ email: '', password: '' });
    const isLoginButtonDisabled = !isPasswordValid(loginForm.password) || !isEmailValid(loginForm.email);
    const isLoading = useSelector(selectIsLoginLoading);
    const dispatch = useDispatch();
    

    if (isLoggedIn) {
        return <Redirect to="/profile" />
    }

    const handleLogin = () => {
        dispatch(loginRequest(loginForm.email, loginForm.password));
    };

    const handleKeyPress = (e: any) => {
        if (e.keyCode === 13 || e.which === 13) {
            if (!isLoginButtonDisabled) {
                handleLogin();
                return;
            }
        }
    };

    const handleInputOnChange = (key: string, value: string) => {
        setLoginForm({ ...loginForm, [key]: value });
    };

    return (
        <IonPage>
            <IonContent className="login-wrapper login-wrapper--login">
                <IonHeader mode="md" className="secondary-header">
                    <IonToolbar className="main">
                        <IonButtons slot="start">
                            <IonBackButton className="back-button" color="warning" mode="ios" icon={backArrow} text="" defaultHref="/" />
                        </IonButtons>
                        <IonTitle className="ion-text-center">Inloggen</IonTitle>
                        <IonButtons slot="end">
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                {error && (
                    <IonAlert
                        isOpen={true}
                        header={'Foutmelding'}
                        message={error.response.error.message}
                        buttons={['OK']}
                    />
                )}
                <IonLoading
                    isOpen={isLoading}
                    message={'Please wait...'}
                />
                <IonGrid>
                    <IonRow className="ion-padding-top ion-text-center ion-justify-content-center">
                        <IonCol size="8">
                            <IonText className="login__text">
                                Fijn dat je weer terug bent
                    </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top ion-text-center ion-justify-content-center">
                        <IonCol size="10">
                            <IonButton
                                className="login__button login__button--primary"
                                expand="block"
                                size="large"
                            >
                                <IonIcon className="login__icon" slot="start" icon={insta} />
                                <span>Log in met Instagram</span>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top ion-text-center ion-justify-content-center">
                        <IonCol size="8">
                            <IonText className="login__text">
                                of...
                    </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top ion-justify-content-center">
                        <IonCol size="10">
                            <IonInput
                                className="ion-text-center input"
                                type="email"
                                placeholder="Gebruikersnaam / e-mail"
                                value={loginForm.email}
                                name='email'
                                onIonInput={(event) => handleInputOnChange((event.target as HTMLIonInputElement).name, (event.target as HTMLInputElement).value)}
                                onKeyPress={(e) => handleKeyPress(e)}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top ion-justify-content-center">
                        <IonCol size="10">
                            <IonInput
                                className="ion-text-center input"
                                type="password"
                                name="password"
                                placeholder="Wachtwoord"
                                value={loginForm.password}
                                onIonInput={(event) => handleInputOnChange((event.target as HTMLIonInputElement).name, (event.target as HTMLInputElement).value)}
                                onKeyPress={(e) => handleKeyPress(e)}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top ion-text-center ion-justify-content-center">
                        <IonCol size="8">
                            <a className="login__link" href="/">
                                wachtwoord vergeten?
                            </a>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top ion-justify-content-center">
                        <IonCol size="6">
                            <IonButton
                                className="login__button login__button--tertiary"
                                expand="block"
                                size="large"
                                onClick={() => handleLogin()}
                                disabled={isLoginButtonDisabled}
                            >
                                <span>Inloggen</span>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Login;
