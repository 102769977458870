import api from "../api";
import Response from "../response";
import { Observable } from "rxjs";

export interface TokenResponse {
    token: string;
}


export const login = (email: string, password: string): Observable<Response<TokenResponse>> => {
    return api<Response<TokenResponse>>('/auth/authenticate', 'POST', {}, {
        email: email,
        password: password,
    }); 
}

export const adminLogin = (): Observable<Response<TokenResponse>> => {
    return api<Response<TokenResponse>>('/auth/authenticate', 'POST', {}, {
        email: "mail@swibblr.com",
        password: "Wolfpack2018"
    }); 
}