import { User } from '../../http/requests/users';
import { LOAD_USERS, LOAD_USERS_SUCCESS, LOAD_USERS_FAILED } from './users.action-types';

export interface LoadUsersAction {
    type: typeof LOAD_USERS,
}

export const loadUsers = (): LoadUsersAction => ({
    type: LOAD_USERS
});

export interface LoadUsersSuccessAction {
    type: typeof LOAD_USERS_SUCCESS,
    payload: User[],
}

export const loadUsersSuccess = (users: User[]): LoadUsersSuccessAction => ({
    type: LOAD_USERS_SUCCESS,
    payload: users,
});

export interface LoadUsersFailedAction {
    type: typeof LOAD_USERS_FAILED,
    payload: Object,
}

export const loadUsersFailed = (error: Object): LoadUsersFailedAction => ({
    type: LOAD_USERS_FAILED,
    payload: error,
});

export type UsersActions =
    LoadUsersAction |
    LoadUsersSuccessAction |
    LoadUsersFailedAction;