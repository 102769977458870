import { Epic, ofType } from 'redux-observable';
import { RootActions } from '..';
import { ProductsActions, loadProductsSuccess } from './products.actions';
import { LOAD_PRODUCTS } from './products.action-types';
import { getProducts } from '../../http/requests/products';
import { switchMap, map } from 'rxjs/operators';

export const loadProductsEpic: Epic<RootActions, ProductsActions> = action$ => action$.pipe(
    ofType(LOAD_PRODUCTS),
    switchMap(() => {
        return getProducts().pipe(
            map(response => loadProductsSuccess(response.data))
        )
    })
)

export const productsEpics = [loadProductsEpic];
