import { Error } from '../../http/interfaces/Error';
import { CreateChannelPayload, Channel } from "../../http/interfaces/Channel";
import { LOAD_CHANNELS, LOAD_CHANNELS_SUCCESS, LOAD_CHANNELS_FAILED, CREATE_CHANNEL, CREATE_CHANNEL_SUCCESS, CREATE_CHANNEL_FAILED, DELETE_CHANNEL, DELETE_CHANNEL_SUCCESS, DELETE_CHANNEL_FAILED } from "./channels.action-types";

export interface LoadChannelsAction {
    type: typeof LOAD_CHANNELS,
}

export const loadChannels = (): LoadChannelsAction => ({
    type: LOAD_CHANNELS
});

export interface LoadChannelsSuccessAction {
    type: typeof LOAD_CHANNELS_SUCCESS,
    payload: Channel[],
}

export const loadChannelsSuccess = (channels: Channel[]): LoadChannelsSuccessAction => ({
    type: LOAD_CHANNELS_SUCCESS,
    payload: channels,
});

export interface LoadChannelsFailedAction {
    type: typeof LOAD_CHANNELS_FAILED,
    payload: Error,
}

export const loadChannelsFailed = (error: Error): LoadChannelsFailedAction => ({
    type: LOAD_CHANNELS_FAILED,
    payload: error,
});

export interface CreateChannelRequestAction {
    type: typeof CREATE_CHANNEL
    payload: CreateChannelPayload,
}

export const createChannelRequest = (payload: CreateChannelPayload): CreateChannelRequestAction => ({
    type: CREATE_CHANNEL,
    payload
});

export interface CreateChannelRequestSuccessAction {
    type: typeof CREATE_CHANNEL_SUCCESS,
    payload: Channel,
}

export const createChannelRequestSuccess = (channel: Channel): CreateChannelRequestSuccessAction => ({
    type: CREATE_CHANNEL_SUCCESS,
    payload: channel,
});

export interface CreateChannelRequestFailedAction {
    type: typeof CREATE_CHANNEL_FAILED
    payload: Error,
}

export const createChannelRequestFailed = (error: Error): CreateChannelRequestFailedAction => ({
    type: CREATE_CHANNEL_FAILED,
    payload: error,
});

export interface DeleteChannelRequestAction {
    type: typeof DELETE_CHANNEL,
    payload: number,
}

export const deleteChannelRequest = (id: number): DeleteChannelRequestAction => ({
    type: DELETE_CHANNEL,
    payload: id,
})

export interface DeleteChannelRequestSuccessAction {
    type: typeof DELETE_CHANNEL_SUCCESS
    payload: number,
}

export const deleteChannelRequestSuccess = (id: number): DeleteChannelRequestSuccessAction => ({
    type: DELETE_CHANNEL_SUCCESS,
    payload: id
})

export interface DeleteChannelRequestFailedAction {
    type: typeof DELETE_CHANNEL_FAILED,
    payload: Error
}

export const deleteChannelRequestFailed = (error: Error): DeleteChannelRequestFailedAction => ({
    type: DELETE_CHANNEL_FAILED,
    payload: error
})

export type ChannelsActions = 
    LoadChannelsAction |
    LoadChannelsSuccessAction |
    LoadChannelsFailedAction |
    CreateChannelRequestAction |
    CreateChannelRequestSuccessAction |
    CreateChannelRequestFailedAction |
    DeleteChannelRequestAction |
    DeleteChannelRequestSuccessAction |
    DeleteChannelRequestFailedAction;
    