import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import ShareButton from "../../components/shareButton/shareButton";
import { selectChannelProducts } from "../../store/channelProducts/channelProducts.selectors";
import { selectChannel } from "../../store/channels/channels.selectors";
import { backArrow } from "../../theme/icons";
import "./ChannelSlider.scss";
import SliderRow from "../../components/slider/sliderRow/sliderRow";
import { dummyHeads } from "../../http/dummyHeads";

interface ChannelProps
    extends RouteComponentProps<{
        id: string;
    }> {}

const ChannelSlider: React.FC<ChannelProps> = ({ match }) => {
    const channelId = +match.params.id;
    const channel = useSelector(selectChannel(channelId));
    const products = useSelector(selectChannelProducts(+match.params.id));

    return (
        <IonPage>
            <IonHeader mode="md" className="secondary-header">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            className="back-button"
                            color="warning"
                            mode="ios"
                            icon={backArrow}
                            text=""
                            defaultHref="/"
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle className="ion-text-center">
                        {channel ? channel.name : "Channel"}
                    </IonTitle>
                    <ShareButton title={channel ? channel.name : "Channel"} />
                </IonToolbar>
            </IonHeader>
            <IonContent className="main">
                {products.map((product) => (
                    <link
                        key={product.id}
                        rel="preload"
                        href={product.img_url}
                        as="image"
                    />
                ))}
                <div className="channel-slider-wrapper">
                    {products !== undefined && products.length !== 0 && (
                        <Fragment>
                            <SliderRow
                                productsData={dummyHeads.filter(
                                    (head) => head.gender === channel.gender
                                )}
                            />
                            <SliderRow
                                productsData={products.filter(
                                    (product) => product.slider_row === "top"
                                )}
                            />
                            <SliderRow
                                productsData={products.filter(
                                    (product) => product.slider_row === "bottom"
                                )}
                            />
                            <SliderRow
                                productsData={products.filter(
                                    (product) => product.slider_row === "shoes"
                                )}
                            />
                        </Fragment>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default ChannelSlider;
