import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonLabel, IonLoading, IonPage, IonRow, IonSegment, IonSegmentButton, IonToolbar } from '@ionic/react';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectChannels, selectChannelsLoading } from '../../store/channels/channels.selectors';
import './Channels.scss';
import { Channel } from '../../http/interfaces/Channel';

type ChannelFilterOption = 'all' | 'men' | 'women';

const Channels: React.FC = () => {
  const isLoading = useSelector(selectChannelsLoading);
  const channels = useSelector(selectChannels);
  const [channelFilterOption, setChannelFilterOption] = useState<ChannelFilterOption>('all');

  type ChannelCategory = 'Men' | 'Women' | '';

  function isChannelIncludedInFilter(channel: Channel): boolean {
    if (channelFilterOption === 'men') {
      return getCategory(channel) === 'Men';
    }

    if (channelFilterOption === 'women') {
      return getCategory(channel) === 'Women';
    }

    return true;
  }

  function getCategory(channel: Channel): ChannelCategory {
    if (channel.gender === 'female') {
      return 'Women';
    }

    if (channel.gender === 'male') {
      return 'Men';
    }

    return '';
  }

  return (
    <IonPage>
      <IonHeader mode="md" className="main-header">
      <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center cta-title cta-title__column">
              <h5 className="cta-title cta-title__text">Inspiratie nodig? Bekijk featured channels van Swipestyle en de community.</h5>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonToolbar>
          <IonSegment onIonChange={e => setChannelFilterOption(e.detail.value as ChannelFilterOption)} value={channelFilterOption}>
            <IonSegmentButton value="all">
              <IonLabel className="segment-button-text">All</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="women">
              <IonLabel className="segment-button-text">Women</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="men">
              <IonLabel className="segment-button-text">Men</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollEvents scrollY>
        <IonLoading
          isOpen={isLoading}
          message={'Please wait...'}
        />
        {channels
          .filter(isChannelIncludedInFilter)
          .map((channel) => ( 
            <Fragment key={channel.id}>
              <IonCard className="channel-preview" routerLink={`/channels/${channel.id}`}>
                <IonCardHeader>
                  <IonImg src={channel.picture.data.full_url} />
                </IonCardHeader>
                <IonCardContent>
                  <IonCardTitle className="ion-text-center">
                    { channel.name }
                  </IonCardTitle>
                  <IonCardSubtitle color="primary" className="ion-text-center">
                    { getCategory(channel) }
                  </IonCardSubtitle>
                </IonCardContent>
              </IonCard>
            </Fragment>
        ))}
      </IonContent>
    </IonPage>
  );
};

export default Channels;
