import { User } from '../../http/requests/users';
import { Reducer } from 'redux';
import { ProfileActions } from './profile.actions';
import { LOAD_PROFILE, LOAD_PROFILE_SUCCESS, LOAD_PROFILE_FAILED, EDIT_PROFILE, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAILED } from './profile.action-types';
import { Error } from '../../http/interfaces/Error';

export interface ProfileState {
    data: User | null;
    loading: boolean;
    loaded: boolean;
    error: Error | null;
    isEdited: boolean;
}

const initialState: ProfileState = {
    data: null,
    loading: false,
    loaded: false,
    error: null,
    isEdited: false,
}

export const profileReducer: Reducer<ProfileState, ProfileActions> = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PROFILE:
            return {
                ...state,
                loading: true,
            };
        case LOAD_PROFILE_SUCCESS:
            return {
                ...state,
                data: { ...action.payload },
                loading: false,
                loaded: true,
            }
        case LOAD_PROFILE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case EDIT_PROFILE:
            return {
                ...state,
                loading: true,
                isEdited: false,
            }
        case EDIT_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                isEdited: true,
            }
        case EDIT_PROFILE_FAILED:
            return {
                ...state,
                loading: false,
                isEdited: false,
                error: action.payload,
            }
        default:
            return state;
    }
}
