import React from 'react';
import { IonPage } from '@ionic/react';
import CreateChannel from '../../../components/createChannel/CreateChannel';
import { RouteComponentProps } from 'react-router';

const CreateChannelPage: React.FC<RouteComponentProps> = ({ history }) => {
    function redirectBack() {
        history.replace('/profile');
    }

    return (
        <IonPage>
            <CreateChannel
                onChannelCreated={() => redirectBack()}
            />
        </IonPage>
    );
};

export default CreateChannelPage;

