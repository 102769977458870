import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { RootActions } from '..';
import { register } from '../../http/requests/register';
import { LoginActions, loginRequest } from '../login/login.actions';
import { REGISTER_REQUEST } from './register.action-types';
import { RegisterActions, RegisterRequestAction, registerRequestFailed, registerRequestSuccess } from './register.actions';


export const registerRequestEpic: Epic<RootActions, RegisterActions | LoginActions> = action$ => action$.pipe(
    ofType<RootActions, RegisterRequestAction>(REGISTER_REQUEST),
    switchMap((response) => {
        const { email, full_name, username, password } = response.payload;
        return (
            register(
                email,
                full_name,
                username,
                password
            ).pipe(
                switchMap(() => [registerRequestSuccess(), loginRequest(email, password)]),
                catchError(error => of(registerRequestFailed(error))),
            )
        )
    })
)

export const registerEpics = [registerRequestEpic];