import { Product } from "../../../http/interfaces/Products";

export function reverseLockMap(items: Product[], lockMap: number[]): number[] {
    return lockMap.map((lockedIndex) => (items.length - 1) - lockedIndex);
  }
  
  export function getRightUnlockedItem(index: number, lockMap: number[]): number {
    let unlockedIndex = index + 1;
    while (lockMap.includes(unlockedIndex)) {
      unlockedIndex++;
    }
    return unlockedIndex;
  }
  
  export function pullStack(items: Product[], lockMap: number[]): Product[] {
    return [...items, items[getRightUnlockedItem(-1, lockMap)]].reduce((result, item, index, shifted) => {
      return [
        ...result,
        lockMap.includes(index)
          ? item
          : shifted[getRightUnlockedItem(index, lockMap)]
      ];
    }, [] as Product[]).slice(0, -1);
  }
  
  export function pushStack(items: Product[], lockMap: number[]): Product[] {
    return pullStack(items.reverse(), reverseLockMap(items, lockMap)).reverse();
  }
  
  export function unlockItem(index: number, lockMap: number[]): number[] {
      return lockMap.filter(lockedItems => lockedItems !== index);
  }

  export function isItemLocked(index: number, lockMap: number[]): boolean {
    const isLocked = lockMap.some(lockMap => lockMap === index);
    if(isLocked){
      return true;
    }
    return false;
  }
