import { IonAvatar, IonBackButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonLoading, IonPage, IonRow, IonText, IonTitle, IonToolbar, IonRouterLink } from '@ionic/react';
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router';
import ShareButton from '../../components/shareButton/shareButton';
import { backArrow } from '../../theme/icons';
import './Profile.scss';
import { getThumbnailWithSize } from '../../http/cropper';
import FollowButton from '../../components/followButton/followButton';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/users/users.selectors';
import { selectProfileLoading } from '../../store/profile/profile.selectors';
import { selectAmountOfFollowers, selectAmountOfFollowees } from '../../store/followers/followings.selectors';

interface ProfileProps extends RouteComponentProps<{
  id: string;
}> { }

const PublicProfile: React.FC<ProfileProps> = ({ match }) => {
  const userId = +match.params.id;
  const user = useSelector(selectUser(userId));
  const isLoading = useSelector(selectProfileLoading);
  const croppedProfileAvatar = cropProfileAvatar();
  const croppedProfileBackground = cropProfileBackground();
  const followeeAmount = useSelector(selectAmountOfFollowees(userId));
  const followersAmount = useSelector(selectAmountOfFollowers(userId))

  function cropProfileAvatar() {
    if (user !== undefined) {
      return getThumbnailWithSize(user.avatar, 'small', 'crop');
    }
  }

  function cropProfileBackground() {
    if (user !== undefined) {
      return getThumbnailWithSize(user.profile_background, 'large', 'contain');
    }
  }

  return (
    <IonPage>
      <IonHeader mode="md" className="secondary-header">
        <IonToolbar className="main">
          <IonButtons slot="start">
            <IonBackButton className="back-button" color="warning" mode="ios" icon={backArrow} text="" defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonTitle className="ion-text-center">{user ? user.full_name : 'Profiel'}</IonTitle>
          <ShareButton title={user ? user.full_name : 'Profiel'} />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading
          isOpen={isLoading}
          message={'Please wait...'}
        />
        <IonGrid className="ion-padding-top ion-padding-bottom profile-header"
          style={{ background: 'url(' + croppedProfileBackground + ')' }}
        >
          <IonRow className="ion-align-items-center">
            <IonCol className="ion-text-center">
              <IonRouterLink routerLink={`${match.url}/followings/followers`}>
                <IonText className="profile-header__text" color="light">
                  <b className="profile-header__text--number">{followersAmount}</b><br />
                  {followersAmount > 1 ? 'volgers' : 'volger'}
                </IonText>
              </IonRouterLink>
            </IonCol>
            <IonCol>
              <IonAvatar className="profile-header__avatar profile-header__avatar--centered">
                <img
                  src={croppedProfileAvatar}
                  alt=""
                />
              </IonAvatar>
            </IonCol>
            <IonCol className="ion-text-center">
              <IonRouterLink routerLink={`${match.url}/followings/followees`}>
                <IonText className="profile-header__text" color="light">
                  <b className="profile-header__text--number">{followeeAmount}</b><br />
                  {followeeAmount > 1 ? 'volgeling' : 'volgelingen'}
                </IonText>
              </IonRouterLink>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid className="ion-margin-top">
          <section className="profile-details">
            <IonRow class="ion-justify-content-center" >
              <IonCol className="ion-text-center">
                <IonText>
                  <b className="profile-details__name">{user ? user.full_name : 'Mijn profiel'}</b>
                  <b className="profile-details__name profile-details__name--handle">(@{user ? user.username : 'Profielnaam'})</b>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow class="ion-justify-content-center" >
              <IonCol className="ion-text-center">
                <IonText>
                  {user ? user.bio : 'Omschrijving'}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow class="ion-justify-content-center" >
              <IonCol className="ion-text-center">
                <IonText>
                  <a href={user ? user.website : 'https://www.google.nl/'}>
                    {user ? user.website : 'https://www.google.nl/'}
                  </a>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow class="ion-justify-content-center" >
              <IonCol className="ion-text-center">
                <FollowButton user={user} />
              </IonCol>
            </IonRow>
          </section>
          <IonRow class="ion-justify-content-center ion-margin-top" >
            <IonCol className="ion-text-center">
              <IonText className="profile-channels">
                My channels
              </IonText>
            </IonCol>
          </IonRow>
          {user &&
            user.channels
              .map((sortedChannels) => (
                <Fragment key={sortedChannels.id}>
                  <IonCard className="channel-preview" routerLink={`${match.url}/channels/${sortedChannels.id}`}>
                    <IonCardHeader>
                      <IonImg src={sortedChannels.picture.data.full_url} />
                    </IonCardHeader>
                    <IonCardContent>
                      <IonCardTitle className="ion-text-center">
                        {sortedChannels.name}
                      </IonCardTitle>
                    </IonCardContent>
                  </IonCard>
                </Fragment>
              ))}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PublicProfile;
