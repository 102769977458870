import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonPage, IonSearchbar, IonSegment, IonSegmentButton, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadChannels } from '../../store/channels/channels.actions';
import { selectChannels, selectChannelsLoading } from '../../store/channels/channels.selectors';
import { loadProducts } from '../../store/products/products.actions';
import { selectProducts, selectProductsLoading } from '../../store/products/products.selectors';
import { loadUsers } from '../../store/users/users.actions';
import { selectUsers, selectUsersLoading } from '../../store/users/users.selectors';
import './Search.scss';

type SearchableCategory = 'Channel' | 'Product' | 'User';
type SegmentFilterOption = 'all' | 'channels' | 'products' | 'users';

interface Searchable {
  key?: number;
  id: number;
  name: string;
  type: SearchableCategory;
}

interface SearchResults {
  [key: string]: Searchable[];
}

const Search: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectChannelsLoading && selectProductsLoading && selectUsersLoading);
  const channels = useSelector(selectChannels);
  const products = useSelector(selectProducts);
  const users = useSelector(selectUsers);
  const [searchText, setSearchText] = useState('');

  const [activeTab, setActiveTab] = useState<SegmentFilterOption>('all');
  const [searchableList, setSearchableList] = useState<Searchable[]>([]);

  useIonViewWillEnter(() => {
    dispatch(loadChannels());
    dispatch(loadProducts());
    dispatch(loadUsers());
  });

  function groupSearchResults(searchableList: Searchable[], searchText: string, activeTab: string): SearchResults {
    const filteredList = searchableList.filter(searchableItem => isItemIncluded(searchableItem, searchText))

    const channels = filteredList.filter(item => item.type === 'Channel');
    const products = filteredList.filter(item => item.type === 'Product');
    const users = filteredList.filter(item => item.type === 'User');

    const channelsGroup: SearchResults = channels.length ? { 'channel': channels } : {};
    const productsGroup: SearchResults = products.length ? { 'product': products } : {};
    const usersGroup: SearchResults = users.length ? { 'user': users } : {};

    switch (activeTab) {
      case 'channels':
        return channelsGroup;
      case 'products':
        return productsGroup;
      case 'users':
        return usersGroup;
      default:
        return {
          ...channelsGroup,
          ...productsGroup,
          ...usersGroup
        };
    }
  }

  function getCategoryTitle(category: string) {
    switch (category) {
      case 'channel':
        return 'Channels';
      case 'product':
        return 'Products';
      case 'user':
        return 'Users';
      default:
        return {
        };
    }
  }

  useEffect(() => {
    const list: Searchable[] = [
      ...channels.map(channel => {
        return {
          id: channel.id,
          name: channel.name,
          type: 'Channel' as SearchableCategory,
        }
      }),
      ...products.map(product => {
        return {
          id: product.id,
          name: product.name,
          type: 'Product' as SearchableCategory,
        }
      }),
      ...users.map(user => {
        return {
          id: user.id,
          name: user.full_name,
          type: 'User' as SearchableCategory,
        }
      }),
    ].map((item, index) => {
      return {
        key: index,
        ...item,
      }
    });
    setSearchableList(list);
  }, [channels, products, users])

  function isItemIncluded(item: Searchable, searchText: string): boolean {
    const itemName = item.name.toLowerCase();
    const search = searchText.toLowerCase();
    return itemName.includes(search);
  }

  return (
    <IonPage>
      <IonHeader mode="md" className="main-header">
        <IonToolbar className="toolbar-wrapper">
          <IonSearchbar
            className="searchbar"
            value={searchText}
            autocomplete="on"
            onIonChange={e => setSearchText(e.detail.value!)}>
          </IonSearchbar>
        </IonToolbar>
        <IonToolbar>
          <IonSegment
            onIonChange={e => setActiveTab(e.detail.value as SegmentFilterOption)}
            value={activeTab}
          >
            <IonSegmentButton value="all">
              <IonLabel className="segment-button-text">All</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="channels">
              <IonLabel className="segment-button-text">Channels</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="products">
              <IonLabel className="segment-button-text">Products</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="users">
              <IonLabel className="segment-button-text">Users</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading
          isOpen={isLoading}
          message={'Please wait...'}
        />
        {
          // TODO: make a separate component to display search results
          Object.keys(groupSearchResults(searchableList, searchText, activeTab)).map(category => (
            <IonList key={category}>
              <IonListHeader>
                <IonLabel>{getCategoryTitle(category)}</IonLabel>
              </IonListHeader>
              {groupSearchResults(searchableList, searchText, activeTab)[category].map(item => (
                <IonItem key={item.key} routerLink={`/search/${category}/${item.id}`}>
                  <IonLabel>{item.name}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          ))}
      </IonContent>
    </IonPage>
  );
};

export default Search;
