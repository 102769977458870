import { RootState } from "..";
import { ChannelsState } from "./channels.reducer";
import { mapEntitiesToArray, Entities} from '../entities';
import { Error } from '../../http/interfaces/Error';
import { selectProfile } from "../profile/profile.selectors";
import { Channel } from "../../http/interfaces/Channel";

export const selectChannelsState = (state: RootState): ChannelsState => {
    return state.channels;
}

export const selectChannelEntities = (state: RootState): Entities<Channel> => {
    return selectChannelsState(state).entities;
}

export const selectChannelsLoading = (state: RootState): boolean => {
    return selectChannelsState(state).loading;
}

export const selectChannels = (state: RootState): Channel[] => {
    return mapEntitiesToArray(selectChannelEntities(state));
}

export const selectChannel = (id: number) => (state: RootState): Channel => {
    return selectChannels(state).filter(channel => channel.id === id)[0];
}

export const selectChannelError = (state: RootState): Error | null => {
    return selectChannelsState(state).error;
}

export const selectisChannelCreated = (state: RootState): boolean => {
    return selectChannelsState(state).isCreated;
}

export const selectCurrentUserChannels = (state: RootState): Channel[] => {
    const profile = selectProfile(state);
    if (!profile) {
        return [];
    }
    const ownerId = profile.id;
    return selectChannels(state).filter(channel => channel.owner.id === ownerId)
}
