import { IonItem, IonAvatar, IonLabel, IonAlert } from "@ionic/react";
import React, { useState, Fragment } from "react";
import { addChannelProduct } from "../../store/channelProducts/channelProducts.actions";
import { useDispatch, useSelector } from "react-redux";
import { selectIsProductInChannel, selectIsChannelAndProductGenderUnequal} from "../../store/channelProducts/channelProducts.selectors";
import { Channel } from "../../http/interfaces/Channel";

type ChannelItemProps = {
    channel: Channel;
    productId: number;
    handleIsChannelListOpen: Function;
}

const ChannelItem: React.FC<ChannelItemProps> = ({ channel, productId, handleIsChannelListOpen }) => {
    const dispatch = useDispatch();
    const isProductInChannel = useSelector(selectIsProductInChannel(channel.id, productId));
    const isChannelAndProductGenderUnequal = useSelector(selectIsChannelAndProductGenderUnequal(channel.id, productId));
    const [showProductInChannelAlert, setShowProductInChannelAlert] = useState(false);
    const [showChannelAndProductGenderUnequalAlert, setShowChannelAndProductGenderUnequalAlert] = useState(false);

    const handleAddChannelProduct = (channelId: number) => {
        if (isProductInChannel) {
            return setShowProductInChannelAlert(true);
        }

        if(isChannelAndProductGenderUnequal) {
            return setShowChannelAndProductGenderUnequalAlert(true);
        }

        dispatch(addChannelProduct({
            channel: channelId,
            product: productId,
        }));

        handleIsChannelListOpen();
    };

    return (
        <Fragment>
            <IonAlert isOpen={showProductInChannelAlert} onDidDismiss={() => setShowProductInChannelAlert(false)} header={'Foutmelding'} message={'Dit product bestaat al in het kanaal'} buttons={['OK']} />;
            <IonAlert isOpen={showChannelAndProductGenderUnequalAlert} onDidDismiss={() => setShowChannelAndProductGenderUnequalAlert(false)} header={'Foutmelding'} message={'Het geslacht van het product komt niet overeen met dat van het kanaal.'} buttons={['OK']} />;
            <IonItem className="channel-list__item" onClick={() => handleAddChannelProduct(channel.id)}>
                <IonAvatar className="channel-list__avatar" slot="start">
                    <img src={channel.picture.data.full_url} alt="" />
                </IonAvatar>
                <IonLabel className="channel-list__text">
                    {channel.name}
                </IonLabel>
            </IonItem>
        </Fragment>
    )
}

export default ChannelItem;