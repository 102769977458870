import { ChannelProducts, ChannelProductsRequest } from '../../http/requests/channelProducts';
import { Error } from '../../http/interfaces/Error';
import { LOAD_CHANNEL_PRODUCTS, LOAD_CHANNEL_PRODUCTS_SUCCESS, LOAD_CHANNEL_PRODUCTS_FAILED, ADD_CHANNEL_PRODUCT, ADD_CHANNEL_PRODUCT_SUCCESS, ADD_CHANNEL_PRODUCT_FAILED } from './channelProducts.action-types';

export interface LoadChannelProducts {
    type: typeof LOAD_CHANNEL_PRODUCTS,
}

export const loadChannelProducts = (): LoadChannelProducts => ({
    type: LOAD_CHANNEL_PRODUCTS,
});

export interface LoadChannelProductsSuccessAction {
    type: typeof LOAD_CHANNEL_PRODUCTS_SUCCESS,
    payload: ChannelProducts[],
}

export const loadChannelProductsSuccess = (channelProducts: ChannelProducts[]): LoadChannelProductsSuccessAction => ({
    type: LOAD_CHANNEL_PRODUCTS_SUCCESS,
    payload: channelProducts,
});

export interface LoadChannelProductsFailedAction {
    type: typeof LOAD_CHANNEL_PRODUCTS_FAILED,
    payload: Error,
}

export const loadChannelProductsFailed = (error: Error): LoadChannelProductsFailedAction => ({
    type: LOAD_CHANNEL_PRODUCTS_FAILED,
    payload: error,
});

export interface AddChannelProductAction {
    type: typeof ADD_CHANNEL_PRODUCT,
    payload: ChannelProductsRequest
}

export const addChannelProduct = (channelProduct: ChannelProductsRequest): AddChannelProductAction => ({
    type: ADD_CHANNEL_PRODUCT,
    payload: channelProduct,
})

export interface AddChannelProductSuccessAction {
    type: typeof ADD_CHANNEL_PRODUCT_SUCCESS,
    payload: ChannelProducts,
}

export const addChannelProductSuccess = (channelProduct: ChannelProducts): AddChannelProductSuccessAction => ({
    type: ADD_CHANNEL_PRODUCT_SUCCESS,
    payload: channelProduct
})

export interface AddChannelProductFailedAction {
    type: typeof ADD_CHANNEL_PRODUCT_FAILED,
    payload: Error
}

export const addChannelProductFailed = (error: Error): AddChannelProductFailedAction => ({
    type: ADD_CHANNEL_PRODUCT_FAILED,
    payload: error
})

export type ChannelProductsActions = 
    LoadChannelProducts |
    LoadChannelProductsSuccessAction |
    LoadChannelProductsFailedAction |
    AddChannelProductAction |
    AddChannelProductSuccessAction |
    AddChannelProductFailedAction;
    