import React, { Fragment, useState } from 'react';
import { IonButton, IonToast, IonAlert, IonBadge } from '@ionic/react';
import './followButton.scss';
import { User } from '../../http/requests/users';
import useFollow from './useFollow';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../../store/login/login.selectors';
import { selectFollowingsError, selectIsFriends, selectIsUserFollowingMe } from '../../store/followers/followings.selectors';
import { selectIsItMe } from '../../store/profile/profile.selectors';
import FriendsModal from '../friendsModal/friendsModal';
import { subscribeActionAfter } from 'redux-subscribe-action';
import { UNFOLLOW_USER_SUCCESS } from '../../store/followers/followings.action-types';

interface FollowButtonProps {
    user: User,
}

const FollowButton: React.FC<FollowButtonProps> = ({ user }: FollowButtonProps) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const error = useSelector(selectFollowingsError, () => false);
    const isItMe = useSelector(selectIsItMe(user.id));
    const areWeFriends = useSelector(selectIsFriends(user.id));
    const [isFriendsModalOpen, setIsFriendsModalOpen] = useState(false);
    const unsubscribe = subscribeActionAfter(UNFOLLOW_USER_SUCCESS, () => { setIsFriendsModalOpen(false); });
    const userFollowsMe = useSelector(selectIsUserFollowingMe(user.id));

    const [
        isFollowing,
        isSuccesMessageOpened,
        onCloseSuccessMessage,
        handleClick,
    ] = useFollow(user.id);

    if (!isLoggedIn) {
        return <Fragment></Fragment>;
    }

    if (isItMe) {
        return <IonBadge>It is you</IonBadge>
    }

    return (
        <Fragment>
            <IonToast
                isOpen={isSuccesMessageOpened}
                message={isFollowing ? 'You are following' : 'You are not following'}
                onDidDismiss={() => onCloseSuccessMessage()}
                duration={2500}
            />
            {error && (
                <IonAlert
                    isOpen={true}
                    header={'Foutmelding'}
                    message={error.response.error.message}
                    buttons={['OK']}
                />
            )}
            {!areWeFriends ? (
                <Fragment>
                    {!userFollowsMe ? (
                <IonButton className="follow-button" shape="round" onClick={() => handleClick()}>
                    {isFollowing ? 'Ontvolg' : 'Volg'}
                </IonButton>
                    ) : (
                    <IonButton className="follow-button" shape="round" onClick={() => handleClick()}>
                        Follow back
                    </IonButton>   
                )}
                </Fragment>
            ) : (
                <IonButton className="follow-button" shape="round" onClick={() => setIsFriendsModalOpen(true)}>
                    Vrienden
                </IonButton>
            )}
            {isFriendsModalOpen &&
                <FriendsModal
                    user={user}
                    onClose={() => setIsFriendsModalOpen(false)}
                    onUnfollow={() => handleClick() && unsubscribe()}
                    onDidmiss={() => setIsFriendsModalOpen(false)}
                />
            }
        </Fragment>
    )
}

export default FollowButton;