import { Product } from "./interfaces/Products";

export const dummyHeads: Product[] = [
    {
        id: -1,
        name: "Head",
        img_url: "/assets/heads/women/Caucasion.jpg",
        description: "",
        slider_row: "head",
        brand: "",
        merchant: "",
        price: 0,
        shop_url: "",
        alternate_img_url: "",
        gender: "female",
    },
    {
        id: -2,
        name: "Head",
        img_url: "/assets/heads/women/Colored.jpg",
        description: "",
        slider_row: "head",
        brand: "",
        merchant: "",
        price: 0,
        shop_url: "",
        alternate_img_url: "",
        gender: "female",
    },
    {
        id: -3,
        name: "Head",
        img_url: "/assets/heads/women/Latin.jpg",
        description: "",
        slider_row: "head",
        brand: "",
        merchant: "",
        price: 0,
        shop_url: "",
        alternate_img_url: "",
        gender: "female",
    },
    {
        id: -4,
        name: "Head",
        img_url: "/assets/heads/women/Mixed.jpg",
        description: "",
        slider_row: "head",
        brand: "",
        merchant: "",
        price: 0,
        shop_url: "",
        alternate_img_url: "",
        gender: "female",
    },
    {
        id: -5,
        name: "Head",
        img_url: "/assets/heads/men/brownskin.png",
        description: "",
        slider_row: "head",
        brand: "",
        merchant: "",
        price: 0,
        shop_url: "",
        alternate_img_url: "",
        gender: "male",
    },
    {
        id: -6,
        name: "Head",
        img_url: "/assets/heads/men/latino.png",
        description: "",
        slider_row: "head",
        brand: "",
        merchant: "",
        price: 0,
        shop_url: "",
        alternate_img_url: "",
        gender: "male",
    },
    {
        id: -7,
        name: "Head",
        img_url: "/assets/heads/men/whiteBeard.png",
        description: "",
        slider_row: "head",
        brand: "",
        merchant: "",
        price: 0,
        shop_url: "",
        alternate_img_url: "",
        gender: "male",
    },
    {
        id: -8,
        name: "Head",
        img_url: "/assets/heads/men/white.png",
        description: "",
        slider_row: "head",
        brand: "",
        merchant: "",
        price: 0,
        shop_url: "",
        alternate_img_url: "",
        gender: "male",
    },
];
