import { DirectusFile } from "./interfaces/DirectusFile";

type imageSize = 'small' | 'medium' | 'large';
type imageCrop = 'contain' | 'crop';

export function getThumbnail(image: DirectusFile): string {
    const pic = image.data.thumbnails.find(thumbnail => thumbnail.url.includes('?key=thumbnail'));
    if (pic) {
        return pic.url;
    }
    return image.data.full_url;
}

export function getThumbnailWithSize(image: DirectusFile, size: imageSize, crop: imageCrop): string {
    const pic = image.data.thumbnails.find(thumbnail => thumbnail.url.includes(`?key=directus-${size}-${crop}`));
    if (pic) {
        return pic.url;
    }
    return image.data.full_url;
}