import { RootState } from '..';
import { selectProduct } from "../products/products.selectors";
import { Error } from '../../http/interfaces/Error';
import { selectChannel } from '../channels/channels.selectors';
import { Product } from '../../http/interfaces/Products';

export const selectChannelProductsState = (state: RootState) => {
    return state.channelProducts;
}

export const selectChannelProductsRelations = (state: RootState) => {
    return selectChannelProductsState(state).data;
}

export const selectChannelProducts = (channelId: number) => (state: RootState): Product[] => {
    return selectChannelProductsRelations(state)
        .filter(relation => relation.channel === channelId)
        .map(relation => selectProduct(relation.product)(state))
}

export const selectIsChannelProductsLoading = (state: RootState): boolean => {
    return selectChannelProductsState(state).loading;
}

export const selectIsChannelProductAdded = (state: RootState): boolean => {
    return selectChannelProductsState(state).isAdded;
}

export const selectChannelProductsError = (state: RootState): Error | null => {
    return selectChannelProductsState(state).error;
}

export const selectIsProductInChannel = (channelId: number, productId: number) => (state: RootState): boolean => {
    const channelProducts = selectChannelProducts(channelId)(state);
    return channelProducts.some(product => product.id === productId);
}

export const selectIsChannelAndProductGenderUnequal = (channelId: number, productId: number) => (state: RootState): boolean => {
    const checkProductGender = selectProduct(productId)(state).gender;
    const checkChannelGender = selectChannel(channelId)(state).gender;

    return checkProductGender !== checkChannelGender;

}