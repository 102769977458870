import { RootState } from '..';
import { ProductsState } from './products.reducer';
import { mapEntitiesToArray, Entities} from '../entities';
import { Product } from '../../http/interfaces/Products';


export const selectProductsState = (state: RootState): ProductsState => {
    return state.products;
}

export const selectProductEntities = (state: RootState): Entities<Product> => {
    return selectProductsState(state).entities;
}

export const selectProductsLoading = (state: RootState): boolean => {
    return selectProductsState(state).loading;
}

export const selectProducts = (state: RootState): Product[] => {
    return mapEntitiesToArray(selectProductEntities(state));
}

export const selectProduct = (id: number) => (state: RootState): Product => {
    return selectProductEntities(state)[id];
}

export const selectProductGender = (id: number) => (state: RootState): string => {
    return selectProduct(id)(state).gender;
}