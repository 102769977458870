import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, ignoreElements, map, switchMap, tap } from 'rxjs/operators';
import { RootActions } from '..';
import { login } from '../../http/requests/login';
import { LOGIN_REQUEST, LOGIN_REQUEST_SUCCESS } from './login.action-types';
import { LoginActions, LoginRequestAction, loginRequestFailed, loginRequestSuccess, LoginRequestSuccessAction } from './login.actions';

function saveToken(action: string) {
    localStorage.setItem('token', action)
}

export const loginRequestEpic: Epic<RootActions, LoginActions> = action$ => action$.pipe(
    ofType<RootActions, LoginRequestAction>(LOGIN_REQUEST),
    switchMap((action) => {
        return login(action.payload.email, action.payload.password).pipe(
            map(response => loginRequestSuccess(response.data)),
            catchError(error => of(loginRequestFailed(error)))
        )
    })
)

export const loginRequestSuccessEpic: Epic<RootActions, LoginActions> = action$ =>  action$.pipe(
    ofType<RootActions, LoginRequestSuccessAction>(LOGIN_REQUEST_SUCCESS),
        tap(action => saveToken(action.payload.token)),
        ignoreElements(),
);

export const loginEpics = [loginRequestEpic, loginRequestSuccessEpic];
