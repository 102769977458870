import { IonAlert, IonBackButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonRadio, IonRadioGroup, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './CreateChannel.scss';
import { CreateChannelPayload } from '../../http/interfaces/Channel';
import { selectChannelError, selectChannelsLoading } from '../../store/channels/channels.selectors';
import { createChannelRequest } from '../../store/channels/channels.actions';
import { backArrow, confirm } from '../../theme/icons';
import UploadImage from '../upload-image/UploadImage';
import { dummyImage } from '../../http/dummy-image';
import { subscribeActionAfter } from 'redux-subscribe-action';
import { CREATE_CHANNEL_SUCCESS } from '../../store/channels/channels.action-types';

type CreateChannelFormField = keyof CreateChannelPayload;

interface CreateChannelProps {
    gender?: string;
    onChannelCreated: () => void;
}

const CreateChannel: React.FC<CreateChannelProps> = ({ gender, onChannelCreated }) => {
    const dispatch = useDispatch();
    const error = useSelector(selectChannelError, () => false);
    const isLoading = useSelector(selectChannelsLoading);
    const [createChannel, setCreateChannel] = useState<CreateChannelPayload>({id: 0, name: 'New channel', picture: undefined, gender: gender ? gender : 'male' });
    const unsubscribe = subscribeActionAfter(CREATE_CHANNEL_SUCCESS, () => { onChannelCreated() });

    function handleFile(fileFieldName: CreateChannelFormField, form: FormData) {
        setCreateChannel({
            ...createChannel,
            [fileFieldName]: form,
        })
    }

    const handleInputOnChange = (event: CustomEvent) => {
        if (!event.target) {
            return;
        }

        const key: CreateChannelFormField = (event.target as HTMLIonInputElement).name as CreateChannelFormField;
        const value: any = (event.target as HTMLInputElement).value;
        setCreateChannel({ ...createChannel, [key]: value });
    };

    const handleCreateChannel = () => {
        const payload = {
            ...createChannel,
        }
        dispatch(createChannelRequest(payload));
        unsubscribe();
    };

    return (
        <Fragment>
            <IonHeader className="secondary-header">
                <IonToolbar className="main">
                    <IonButtons slot="start">
                        <IonBackButton className="back-button" icon={backArrow} color="warning"  text="" defaultHref="/"></IonBackButton>
                    </IonButtons>
                    <IonTitle className="ion-text-center">New channel</IonTitle>
                    <IonButtons slot="end">
                        <IonIcon className="confirm-button" icon={confirm} onClick={() => handleCreateChannel()} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {error && (
                    <IonAlert
                        isOpen={true}
                        header={'Foutmelding'}
                        message={error.response.error.message}
                        buttons={['OK']}
                    />
                )}
                <IonLoading
                    isOpen={isLoading}
                    message={'Please wait...'}
                />
                <IonGrid className="ion-padding-top ion-padding-bottom edit-profile">
                    <section className="create-channel">
                        <IonRow className="ion-padding-top ion-justify-content-center">
                            <IonCol size="10">
                                <IonInput
                                    className="ion-text-center input"
                                    type="text"
                                    placeholder="Channel naam"
                                    value={createChannel.name}
                                    name='name'
                                    onIonInput={(event) => handleInputOnChange(event)}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-center">
                            <IonCol size="10">
                                <div className="edit-profile__background">
                                    <UploadImage currentImage={dummyImage} onChange={(form) => handleFile('picture', form)} />
                                </div>
                            </IonCol>
                        </IonRow>
                    </section>
                    <IonRow className="ion-padding-top ion-justify-content-center ion-text-center">
                        <IonCol size="9">
                            <IonRadioGroup name="gender" value={createChannel.gender} onIonChange={(event) => handleInputOnChange(event)}>
                                <IonLabel className="create-channel__text">Geslacht</IonLabel>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol>
                                        <IonItem className="create-channel__radio" lines="none">
                                            <IonRadio className="create-channel__radio" mode="md" value="male" />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol>
                                        <IonItem lines="none">
                                            <IonRadio className="create-channel__radio" mode="md" value="female" />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="create-channel__labels">
                                    <IonCol>
                                        <IonLabel className="create-channel__label">Male</IonLabel>
                                    </IonCol>
                                    <IonCol>
                                        <IonLabel className="create-channel__label">Female</IonLabel>
                                    </IonCol>
                                </IonRow>
                            </IonRadioGroup>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Fragment>
    );
};

export default CreateChannel;
