import { Reducer } from "redux";
import { FollowingsActions } from "./followings.actions";
import { Error } from '../../http/interfaces/Error';
import { Following } from "../../http/interfaces/Following";
import { LOAD_FOLLOWINGS, LOAD_FOLLOWINGS_SUCCESS, LOAD_FOLLOWINGS_FAILED, FOLLOW_USER, FOLLOW_USER_SUCCESS, FOLLOW_USER_FAILED, UNFOLLOW_USER, UNFOLLOW_USER_SUCCESS, UNFOLLOW_USER_FAILED } from "./followings.action-types";

export interface FollowingsState {
    data: Following[];
    loading: boolean;
    loaded: boolean;
    error: Error | null;
}

const initialState: FollowingsState = {
    data: [],
    loading: false,
    loaded: false,
    error: null,
}

export const followingsReducer: Reducer<FollowingsState, FollowingsActions> = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_FOLLOWINGS:
            return {
                ...state,
                loading: true,
            };
        case LOAD_FOLLOWINGS_SUCCESS:
            return {
                ...state,
                data: [...action.payload],
                loading: false,
                loaded: true,
            };
        case LOAD_FOLLOWINGS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case FOLLOW_USER:
            return {
                ...state,
                loading: true,
            };
        case FOLLOW_USER_SUCCESS:
            return {
                ...state,
                data: [ ...state.data, action.payload ],
                loading: false,
                loaded: true,
            };
        case FOLLOW_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
            case UNFOLLOW_USER:
                return {
                    ...state,
                    loading: true,
                };
            case UNFOLLOW_USER_SUCCESS:
                return {
                    ...state,
                    data: state.data.filter(data => data.id !== action.payload),
                    loading: false,
                    loaded: true,
                };
            case UNFOLLOW_USER_FAILED:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
        default:
            return state;
    }
}