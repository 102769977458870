import { IonActionSheet, IonButton, IonButtons, IonIcon } from '@ionic/react';
import { copyOutline, logoWhatsapp, mailOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { share } from '../../theme/icons';

interface ButtonProps {
    title: string,
}

const ShareButton = ({ title }: ButtonProps) => {

    const [showActionSheet, setShowActionSheet] = useState<boolean>(false);

    function shareWhatsapp() {
        let url = `whatsapp://send?text=${window.location.href}`;
        window.location.replace(url);
    }

    function shareEmail() {
        let url = `mailto:Swipestyle@mode.nl?subject=${title}%20via%20Swipestyle&body=${window.location.href}`;
        window.location.replace(url);
    }

    function copyUrl() {
        navigator.clipboard.writeText(window.location.href)
    }

    return (
        <IonButtons slot="end">
            <IonButton
                color="warning"
                onClick={() => {
                    if ((navigator as any).share) {
                        (navigator as any).share({
                            title: title,
                            url: window.location.href,
                        }).then(() => {
                            console.log('Thanks for sharing!');
                        })
                            .catch(console.error);
                    } else {
                        setShowActionSheet(true);
                        console.log(window.location.href);
                    }
                }
                }
            >
                <IonActionSheet
                    isOpen={showActionSheet}
                    onDidDismiss={() => setShowActionSheet(false)}
                    buttons={[{
                        text: 'Deel via Whatsapp',
                        icon: logoWhatsapp,
                        handler: () => shareWhatsapp()
                    }, {
                        text: 'Deel via e-mail',
                        icon: mailOutline,
                        handler: () => shareEmail()
                    }, {
                        text: 'Kopieër link',
                        icon: copyOutline,
                        handler: () => copyUrl()
                    }, {
                        text: 'Cancel',
                        icon: 'close',
                        role: 'cancel',
                        handler: () => console.log('Cancel clicked')
                    }]}
                >
                </IonActionSheet>
                <IonIcon className="share-icon" slot="icon-only" icon={share} />
            </IonButton>
        </IonButtons>
    )
}

export default ShareButton;