import api from "../api";
import Response from "../response";
import { Observable } from "rxjs";
import { RegisterRequest } from '../../store/register/register.actions';

export const register = (email: string, full_name: string, username: string, password: string): Observable<Response<RegisterRequest>> => {
    return api<Response<any>>('/custom/signup', 'POST', {}, {
        email: email,
        full_name: full_name,
        username: username,
        password: password,
    }); 
}
