import { Epic, ofType } from 'redux-observable';
import { RootActions } from '..';
import { ChannelsActions, loadChannelsSuccess, CreateChannelRequestAction, createChannelRequestFailed, createChannelRequestSuccess, DeleteChannelRequestAction, deleteChannelRequestSuccess, deleteChannelRequestFailed } from './channels.actions';
import { LOAD_CHANNELS, LOAD_CHANNELS_SUCCESS, CREATE_CHANNEL, DELETE_CHANNEL } from './channels.action-types';
import { getChannels, createChannel, deleteChannel } from '../../http/requests/channels';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { loadProducts } from '../products/products.actions';
import { loadChannelProducts } from '../channelProducts/channelProducts.actions';
import { CreateChannelPayload, CreateChannelRequest } from '../../http/interfaces/Channel';
import { uploadFile } from '../../http/requests/file';
import { fixChannel } from '../../http/fix.details';

export const loadChannelsEpic: Epic<RootActions, ChannelsActions> = action$ => action$.pipe(
    ofType(LOAD_CHANNELS),
    switchMap(() => {
        return getChannels().pipe(
            map(response => loadChannelsSuccess(response.data))
        )
    })
)

export const loadChannelsSuccessEpic: Epic<RootActions, RootActions> = action$ => action$.pipe(
    ofType(LOAD_CHANNELS_SUCCESS),
    switchMap(() => of(loadProducts(), loadChannelProducts())),
)

function createChannelPayloadToRequest(createChannelPayload: CreateChannelPayload): Observable<CreateChannelRequest> {
    if (createChannelPayload.picture) {
        return uploadFile(createChannelPayload.picture).pipe(
            map(response => ({
                ...createChannelPayload,
                picture: response.data.id,
            } as CreateChannelRequest))
        )
    }

    return of({ ...createChannelPayload } as CreateChannelRequest);
}

export const createChannelEpic: Epic<RootActions, ChannelsActions> = action$ => action$.pipe(
    ofType<RootActions, CreateChannelRequestAction>(CREATE_CHANNEL),
    switchMap((action) => {
        return createChannelPayloadToRequest(action.payload);
    }),
    switchMap((request: CreateChannelRequest) => {
        return (
            createChannel(
                request
            ).pipe(
                map((response) => createChannelRequestSuccess(fixChannel(response.data))),
                catchError(error => of(createChannelRequestFailed(error))),
            )
        )
    }),
    catchError((error) => of(createChannelRequestFailed(error))),
)

export const deleteChannelEpic: Epic<RootActions, ChannelsActions> = action$ => action$.pipe(
    ofType<RootActions, DeleteChannelRequestAction>(DELETE_CHANNEL),
    switchMap((request) => {
        return (
            deleteChannel(
                request.payload
            ).pipe(
                map(() => deleteChannelRequestSuccess(request.payload)),
                catchError(error => of(deleteChannelRequestFailed(error))),
            )
        )
    }),
    catchError(error => of(deleteChannelRequestFailed(error))),
)

export const channelEpics = [loadChannelsEpic, loadChannelsSuccessEpic, createChannelEpic, deleteChannelEpic];