import { Error } from '../../http/interfaces/Error';
import { Following } from '../../http/interfaces/Following';
import { LOAD_FOLLOWINGS, LOAD_FOLLOWINGS_SUCCESS, LOAD_FOLLOWINGS_FAILED, FOLLOW_USER, FOLLOW_USER_SUCCESS, FOLLOW_USER_FAILED, UNFOLLOW_USER, UNFOLLOW_USER_SUCCESS, UNFOLLOW_USER_FAILED } from './followings.action-types';

export interface LoadFollowingsAction {
    type: typeof LOAD_FOLLOWINGS,
}

export const loadFollowings = (): LoadFollowingsAction => ({
    type: LOAD_FOLLOWINGS
});

export interface LoadFollowingsSuccessAction {
    type: typeof LOAD_FOLLOWINGS_SUCCESS,
    payload: Following[],
}

export const loadFollowingsSuccess = (followers: Following[]): LoadFollowingsSuccessAction => ({
    type: LOAD_FOLLOWINGS_SUCCESS,
    payload: followers,
});

export interface LoadFollowingsFailedAction {
    type: typeof LOAD_FOLLOWINGS_FAILED;
    payload: Error;
}

export const loadFollowingsFailed = (error: Error): LoadFollowingsFailedAction => ({
    type: LOAD_FOLLOWINGS_FAILED,
    payload: error,
});

export interface FollowUserAction {
    type: typeof FOLLOW_USER;
    payload: number;
}

export const followUser = (userId: number): FollowUserAction => ({
    type: FOLLOW_USER,
    payload: userId,
})

export interface FollowUserSuccessAction {
    type: typeof FOLLOW_USER_SUCCESS,
    payload: Following
}

export const followUserSuccess = (followUser: Following): FollowUserSuccessAction => ({
    type: FOLLOW_USER_SUCCESS,
    payload: followUser
})

export interface FollowUserFailedAction {
    type: typeof FOLLOW_USER_FAILED,
    payload: Error
}

export const followUserFailed = (error: Error): FollowUserFailedAction => ({
    type: FOLLOW_USER_FAILED,
    payload: error
})

export interface UnfollowUserAction {
    type: typeof UNFOLLOW_USER,
    payload: number;
}

export const unfollowUser = (id: number): UnfollowUserAction => ({
    type: UNFOLLOW_USER,
    payload: id,
})

export interface UnfollowUserSuccessAction {
    type: typeof UNFOLLOW_USER_SUCCESS,
    payload: number,
}

export const unfollowUserSuccess = (id: number): UnfollowUserSuccessAction => ({
    type: UNFOLLOW_USER_SUCCESS,
    payload: id,
})

export interface UnfollowUserFailedAction {
    type: typeof UNFOLLOW_USER_FAILED,
    payload: Error
}

export const unfollowUserFailed = (error: Error): UnfollowUserFailedAction => ({
    type: UNFOLLOW_USER_FAILED,
    payload: error
})

export type FollowingsActions = 
    LoadFollowingsAction |
    LoadFollowingsSuccessAction |
    LoadFollowingsFailedAction |
    FollowUserAction |
    FollowUserSuccessAction |
    FollowUserFailedAction |
    UnfollowUserAction |
    UnfollowUserSuccessAction |
    UnfollowUserFailedAction;