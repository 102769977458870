import { IonBackButton, IonButtons, IonContent, IonHeader, IonLoading, IonPage, IonTitle, IonToolbar, IonIcon, IonSegment, IonSegmentButton, IonLabel, IonList } from '@ionic/react';
import React, { useState } from 'react';
import { backArrow } from '../../theme/icons';
import './followers.scss';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/users/users.selectors';
import { selectFollowers, selectFollowees, selectIsFollowingsLoading } from '../../store/followers/followings.selectors';
import FollowersItem from '../../components/followers/followers';
import FolloweesItem from '../../components/followees/followees';

interface FollowersProps extends RouteComponentProps<{
    id: string;
    type: string;
}> { }

type FollowersFilterOption = 'followers' | 'followees';

const Followers: React.FC<FollowersProps> = ({ match }) => {
    const currentUserId: number = +match.params.id;
    const followingType = match.params.type as FollowersFilterOption;
    const currentUser = useSelector(selectUser(currentUserId));
    const followers = useSelector(selectFollowers(currentUserId));
    const followees = useSelector(selectFollowees(currentUserId));
    const isLoading = useSelector(selectIsFollowingsLoading);
    const [followersFilterOption, setFollowersFilterOption] = useState<FollowersFilterOption>(followingType);

    return (
        <IonPage>
            <IonHeader mode="md" className="secondary-header">
                <IonToolbar className="main">
                    <IonButtons slot="start">
                        <IonBackButton className="back-button" color="warning" mode="ios" icon={backArrow} text="" defaultHref="/" />
                    </IonButtons>
                    <IonTitle className="ion-text-center">
                        {currentUser ? currentUser.full_name : 'Profiel'}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonIcon className="back-button back-button--bigger" color="warning" icon={''} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonHeader mode="md">
                <IonToolbar>
                    <IonSegment onIonChange={e => setFollowersFilterOption(e.detail.value as FollowersFilterOption)} value={followersFilterOption}>
                        <IonSegmentButton value="followers">
                            <IonLabel className="segment-button-text">Volgers</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="followees">
                            <IonLabel className="segment-button-text">Volgend</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonLoading
                    isOpen={isLoading}
                    message={'Please wait...'}
                />
                <IonList className="followers-list">
                    {(followersFilterOption === 'followers') ? (
                        followers.map((user) => (
                            <FollowersItem
                                key={user.id}
                                user={user}
                            />
                        ))
                    ) : (
                        followees.map((user) => (
                            <FolloweesItem
                                key={user.id}
                                user={user}
                            />
                        ))
                    )}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Followers;
