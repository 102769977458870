import authApi from '../auth-api';
import Response from "../response";
import { Observable } from 'rxjs';
import { DirectusFile } from '../interfaces/DirectusFile';

export const uploadFile = (form: FormData): Observable<Response<DirectusFile>> => {
    return authApi<Response<DirectusFile>>(`/files`, 'POST', {}, form);
}

export const deleteFile = (id: number): Observable<Response<DirectusFile>> => {
    return authApi<Response<DirectusFile>>(`/files/${id}`, 'DELETE')
}
