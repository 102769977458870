import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonLoading, IonPage, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, useIonViewWillEnter, IonButtons, IonBackButton } from '@ionic/react';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import ShareButton from '../../components/shareButton/shareButton';
import { selectIsLoggedIn } from '../../store/login/login.selectors';
import { loadProfile } from '../../store/profile/profile.actions';
import { selectProfile, selectProfileLoading } from '../../store/profile/profile.selectors';
import { edit } from '../../theme/icons';
import './Profile.scss';
import { getThumbnailWithSize } from '../../http/cropper';
import { loadUsers } from '../../store/users/users.actions';
import { selectAmountOfFollowers, selectAmountOfFollowees } from '../../store/followers/followings.selectors';
import { selectCurrentUserChannels } from '../../store/channels/channels.selectors';

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const profile = useSelector(selectProfile);
  const channels = useSelector(selectCurrentUserChannels);
  const isLoading = useSelector(selectProfileLoading);
  const croppedProfileAvatar = cropProfileAvatar();
  const croppedProfileBackground = cropProfileBackground();
  const followeeAmount = useSelector(selectAmountOfFollowees(profile ? profile.id : 0));
  const followersAmount = useSelector(selectAmountOfFollowers(profile ? profile.id : 0))

  function cropProfileAvatar() {
    if (profile !== null) {
      return getThumbnailWithSize(profile.avatar, 'small', 'crop');
    }
  }

  function cropProfileBackground() {
    if (profile !== null) {
      return getThumbnailWithSize(profile.profile_background, 'large', 'contain');
    }
  }

  useIonViewWillEnter(() => {
    dispatch(loadProfile());
    dispatch(loadUsers());
  });

  if (!isLoggedIn) {
    return <Redirect to="/" />
  }

  return (
    <IonPage>
      <IonHeader mode="md" className="secondary-header">
        <IonToolbar className="main">
          <IonButtons slot="start">
            <IonBackButton className="back-button" color="warning" mode="ios" icon={''} text="" defaultHref="/" />
          </IonButtons>
          <IonTitle className="ion-text-center">{profile ? profile.full_name : 'Profiel'}</IonTitle>
          <ShareButton title={profile ? profile.full_name : 'Profiel'} />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading
          isOpen={isLoading}
          message={'Please wait...'}
        />
        <IonGrid className="ion-padding-top ion-padding-bottom profile-header"
          style={{ background: 'url(' + croppedProfileBackground + ')' }}
        >
          <IonRow className="ion-align-items-center">
            <IonCol className="ion-text-center">
              <IonRouterLink routerLink={`/profile/${profile?.id}/followings/followers`}>
                <IonText className="profile-header__text" color="light">
                  <b className="profile-header__text--number">{followersAmount}</b><br />
                  {followersAmount > 1 ? 'volgers' : 'volger'}
                </IonText>
              </IonRouterLink>
            </IonCol>
            <IonCol>
              <IonAvatar className="profile-header__avatar profile-header__avatar--centered">
                <img
                  src={croppedProfileAvatar}
                  alt=""
                />
              </IonAvatar>
            </IonCol>
            <IonCol className="ion-text-center">
              <IonRouterLink routerLink={`/profile/${profile?.id}/followings/followees`}>
                <IonText className="profile-header__text" color="light">
                  <b className="profile-header__text--number">{followeeAmount}</b><br />
                  {followeeAmount > 1 ? 'volgeling' : 'volgelingen'}
                </IonText>
              </IonRouterLink>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid className="ion-margin-top">
          <section className="profile-details">
            <IonRow class="ion-justify-content-center" >
              <IonCol className="ion-text-center">
                <IonText>
                  <b className="profile-details__name">{profile ? profile.full_name : 'Mijn profiel'}</b>
                  <b className="profile-details__name profile-details__name--handle">(@{profile ? profile.username : 'Profielnaam'})</b>
                  <IonRouterLink routerLink="/profile/edit">
                    <IonIcon className="profile-details__icon profile-details__icon--margin" icon={edit} />
                  </IonRouterLink>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow class="ion-justify-content-center" >
              <IonCol className="ion-text-center">
                <IonText>
                  {profile ? profile.bio : 'Omschrijving'}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow class="ion-justify-content-center" >
              <IonCol className="ion-text-center">
                <IonText>
                  <a href={profile ? profile.website : 'https://www.google.nl/'}>
                    {profile ? profile.website : 'https://www.google.nl/'}
                  </a>
                </IonText>
              </IonCol>
            </IonRow>
          </section>
          <IonRow class="ion-justify-content-center ion-margin-top" >
            <IonCol className="ion-text-center">
              <IonText>
                <b>My Channels</b>
                <IonRouterLink routerLink="/profile/edit-channel">
                  <IonIcon className="profile-details__icon" icon={edit} />
                </IonRouterLink>
              </IonText>
            </IonCol>
          </IonRow>
          {channels &&
            channels
              .map((sortedChannels) => (
                <Fragment key={sortedChannels.id}>
                  <IonCard className="channel-preview" routerLink={`/profile/channels/${sortedChannels.id}`}>
                    <IonCardHeader>
                      <IonImg src={sortedChannels.picture.data.full_url} />
                    </IonCardHeader>
                    <IonCardContent>
                      <IonCardTitle className="ion-text-center">
                        {sortedChannels.name}
                      </IonCardTitle>
                    </IonCardContent>
                  </IonCard>
                </Fragment>
              ))}
          <IonRow class="ion-justify-content-center" >
            <IonCol className="ion-text-center">
              <IonButton mode="ios" shape="round" className="profile-details__button profile-details__button--create" routerLink="/profile/create-channel">
                Maak nieuwe channel aan
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
