import { DirectusFile } from "./interfaces/DirectusFile";
import { dummyImage } from "./dummy-image";
import { User } from "./requests/users";
import { Channel } from "./interfaces/Channel";

export function fixPicture(picture: DirectusFile | undefined): DirectusFile {
    if (!picture) {
        return dummyImage
    }
    return picture;
}

export function fixChannel(channel: Channel): Channel {
    return {
        ...channel,
        picture: fixPicture(channel.picture),
    }
}

export function fixChannels(channels: Channel[]): Channel[] {
    return channels.map(fixChannel)
}

export function fixUser(user: User): User {
    return {
        ...user,
        avatar: fixPicture(user.avatar),
        profile_background: fixPicture(user.profile_background),        
        channels: user.channels.map(fixChannel)
    }
}

export function fixUsers(users: User[]): User[] {
    return users.map(fixUser)
}