import { RootState } from '..';
import { Error } from '../../http/interfaces/Error';

export const selectAccountState = (state: RootState) => {
    return state.account;
}

export const selectIsLoginLoading = (state: RootState): boolean => {
    return selectAccountState(state).loading;
}

export const selectIsLoggedIn = (state: RootState): boolean => {
    return selectAccountState(state).loggedIn;
}

export const selectError = (state: RootState): Error | null => {
    return selectAccountState(state).error;
}
