import api from '../api';
import Response from "../response";
import { Observable } from 'rxjs';
import authApi from '../auth-api';
import { Channel } from '../interfaces/Channel';

export interface ChannelProducts {
    id: number;
    channel: number;
    product: number;
}

export interface ChannelProductsRequest {
    channel: number;
    product: number;
}

export const getChannelProducts = (): Observable<Response<ChannelProducts[]>> => {
    return api<Response<ChannelProducts[]>>('/items/channel_products'); 
}

export const postChannelProduct = (request: ChannelProductsRequest): Observable<Response<ChannelProducts>> => {
    return authApi<Response<ChannelProducts>>('/items/channel_products', 'POST', {}, request);
}

export interface loadData {
   channels: Channel[];
   channelProducts: ChannelProducts[];
}